import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import adminbg from "../../Static/RawImages/admin-login-bg.png";
import mediusWhite from "../../Static/RawImages/logo-white.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import useAuthStore from "../../store/authStore";
import { IoCloseCircle } from "react-icons/io5";
import { login, sendAgentOTP } from "../../API/commonApis";
import { Button, Modal } from "react-bootstrap";

function Login() {
  const createUser = useAuthStore((state) => state.createUser);
  const removeUser = useAuthStore((state) => state.removeUser);
  const isUserAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const userdata = useAuthStore((state) => state.userData);

  const email = useRef(null);
  const password = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loginErrLog, setLoginErrLog] = useState([]);
  const [authError, setAuthError] = useState("");
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [otpInfo, setOTPInfo] = useState({
    type: null,
    message: null,
  });

  const history = useHistory();
  const otp = useRef(null);
  const updateProfileCompleted = useAuthStore((state) => state.updateProfileCompleted);
  const profileCompleted = useAuthStore((state) => state.profileCompleted);
  const [otpLoading, setOTPLoading] = useState(false);

  const sendOTP = async (body = {}) => {
    const res = await sendAgentOTP({ ...body, email: email.current.value });

    const data = res || {
      status: false,
      message: "Can't send OTP! Please Try Again",
    };

    setOTPInfo({
      type: data.status ? "info" : "danger",
      message: data.message,
    });

    return data;
  };

  const handleOTP = async (e) => {
    e.preventDefault();

    if (!email.current.value) {
      setAuthError("Please Enter Email");
      return;
    }

    if (!password.current.value) {
      setAuthError("Please Enter Password");
      return;
    }

    setLoading(true);

    const res = await sendOTP();

    if (!res.status) {
      setAuthError(res.message);
      setLoading(false);
      return;
    }

    setShowOTPModal(true);
  };

  const handelSubmit = async (e) => {
    e.preventDefault();

    if (!email.current.value) {
      setAuthError("Please Enter Email");
      return;
    }

    if (!password.current.value) {
      setAuthError("Please Enter Password");
      return;
    }

    if (!otp.current.value) {
      setOTPInfo({
        type: "danger",
        message: "Please Enter OTP",
      });
      return;
    }

    setOTPLoading(true);
    setLoading(true);

    try {
      localStorage.clear();
      const formData = new FormData();
      formData.append("email", email.current.value?.toLowerCase()?.trim());
      formData.append("password", password.current.value);
      formData.append("otp", otp.current.value);

      const res = await login(formData);

      setLoading(false);
      setOTPLoading(false);

      let data = res.data;
      let message = res.message;

      if (res === "error") {
        setAuthError("Invalid Credentials.");
      }

      if (!data) {
        if (message?.match(/otp/i)) {
          // Keep the modal open if there's an OTP error
          setOTPInfo({
            type: "danger",
            message,
          });
        } else {
          setShowOTPModal(false); // Close modal for other types of errors
        }

        setAuthError(message ?? "");
        return;
      }

      if (!["admin"].includes(data?.role)) {
        setAuthError("Not A Admin's Email");
        return;
      }

      if (data) {
        if (data.role === "admin") {
          removeUser();
          createUser({
            token: data.token,
            role: "admin",
            userData: {
              email: data.email,
              full_name: data.full_name,
              user_id: data.user_id,
              client_id: data.active_client?.client_id,
              logo_url: data.active_client?.logo_url,
              groups: data.groups,
            },
          });
        } else {
          setLoginErrLog((prevLog) => [...prevLog, "Not A Admin's Email"]);
        }
      }

      setAuthError("");
      setShowOTPModal(false);
      console.log(isUserAuthenticated);
    } catch (error) {
      setAuthError("Unknown Error Occurred");
      console.error(error);
      setLoading(false);
      setOTPLoading(false);
    }
  };

  if (isUserAuthenticated) {
    const userGroups = userdata?.groups || [];

    if (userGroups.includes("all")) {
      history.push("/admin/others/dashboard");
    } else if (userGroups.includes("call_quality")) {
      history.push("/admin/daily-ops/call-quality");
    } else if (userGroups.includes("chat")) {
      history.push("/admin/chat-panel/chat");
    } else if (userGroups.includes("settlement")) {
      history.push("/admin/settlements/manage-settlements");
    } else if (userGroups.includes("agent")) {
      history.push("/admin/agents/manage-agents");
    } else if (userGroups.includes("hr")) {
      history.push("/admin/hr/candidates");
    } else {
      history.push("/admin/others/dashboard");
    }

    return null;
  }

  return (
    <div style={{ backgroundImage: `url(${adminbg})` }} className="admin-login">
      <div className="container-fluid">
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick />
        <div className="row">
          <div className="col-md-12 ps-5">
            <img src={mediusWhite} alt="login" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-sm-6 col-md-5 col-lg-4 pt-5">
            <form onSubmit={handleOTP}>
              <div className="login-text">
                <span>Welcome To</span>
                <br />
                Admin Panel
              </div>
              <div className="mb-3 row">
                <label htmlFor="login_email" className="col-sm-12 col-form-label">
                  Email Id
                </label>
                <div className="col-sm-12">
                  <input ref={email} type="text" id="login_email" className="form-control" />
                </div>
                {loginErrLog.length > 0 && (
                  <div style={{ fontSize: 16 }} role="alert">
                    <div className="text-danger">{loginErrLog[0]}</div>
                  </div>
                )}
              </div>
              <div className="mb-3 row">
                <label htmlFor="login_password" className="col-sm-12 col-form-label">
                  Password
                </label>
                <div className="col-sm-12">
                  <input ref={password} type="password" id="login_password" className="form-control" />
                </div>
                {loginErrLog.length > 1 && (
                  <div style={{ fontSize: 16 }} role="alert">
                    <div className="text-danger">{loginErrLog[1]}</div>
                  </div>
                )}
              </div>
              <div style={{ marginTop: "-0.5rem", fontSize: "0.9rem", color: "red" }} className="mb-3">
                {authError}
              </div>
              <div className="mb-0 row">
                <div className="col-sm-12">
                  <button disabled={loading} type="submit" className="btn btn-primary py-3 w-100">
                    Get OTP
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal size="md" show={showOTPModal} contentClassName="overflow-hidden text-white" style={{ zIndex: 100000 }}>
        <Modal.Header className="d-flex justify-content-between bg-dark">
          <Modal.Title className="text-white">Verify OTP</Modal.Title>
          <button
            onClick={() => {
              setShowOTPModal(false);
              setLoading(false);
            }}
            className="border-0 bg-transparent"
          >
            <IoCloseCircle size={24} color="white" />
          </button>
        </Modal.Header>
        <Modal.Body className="bg-dark">
          {otpInfo.type === "info" ? <p className="pt-2 text-info m-0">{otpInfo.message}</p> : <p className="pt-2 text-danger m-0">{otpInfo.message}</p>}

          <form onSubmit={handelSubmit}>
            <div className="d-flex p-4 gap-4 align-items-center">
              <label htmlFor="login_otp" className="text-white fw-medium">
                Enter OTP:
              </label>
              <input
                ref={otp}
                type="text"
                id="login_otp"
                maxLength={6}
                minLength={6}
                className="form-control"
                inputMode="numeric"
                pattern="\d{6}"
                title="OTP should be 6 digit numeric key"
                required
                style={{ maxWidth: "15ch", letterSpacing: "1ch", fontSize: "1.1rem", fontWeight: "bold" }}
                autoComplete="off"
              />
            </div>
            <Modal.Footer className="d-flex justify-content-start">
              <Button variant="primary" type="submit" disabled={otpLoading}>
                Submit OTP and Login
              </Button>
              <Button
                variant="link"
                className="text-decoration-none text-info"
                onClick={(e) => {
                  e.target.disabled = true;
                  sendOTP({ type: "resend" });
                }}
              >
                Resend OTP
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Login;
