import axios from "axios";
import Qs from "query-string";
import { apiDELETE, apiGET, apiPATCH, apiPOST, apiPUT, handleApiError } from "../utils/apiHandler";
import getBaseUrl from "../apis/getBaseUrl";

const apiUrl = {
  collectionBatch: "/dashboard/api/batch/",
  campaignReport: "/dashboard/api/campaign_report/",
  uploadBatch: "/dashboard/api/batch/",
  collectionAccounts: "/dashboard/api/account/",
  sms: "/message91/api/get_message_converjations_by_customer_mobile_number/",
  whatsapp: "/dashboard/api/get_wati_conversation/",
  disposition: "/dashboard/api/disposition_history/",
  calls: "/call/api/get_call_records/",
  dailyReport: "/dashboard/api/daily-report/",
  checkCurrentUser: "/dashboard/api/check_current_user/",
  postDispoData: "/dashboard/api/add_disposition_data/",
  dashboardDateWiseData: "/dashboard/api/datewise-batch-data/",
  dashboardLiveData: "/dashboard/api/live-feed-data/",
  dashboardDispositionData: "/dashboard/api/get-disposition-data/",
  findCustomer: "/dashboard/api/find_customer/",
  sendWaMessage: "/dashboard/api/send_wati_msg/",
  adminReport: "/dashboard/api/get_report/",
  adminBatchReport: "/dashboard/api/get_lang_report/",
  adminChannel: "/dashboard/api/channel",
  adminAgent: "/dashboard/api/agents_new",
  adminChannelRules: "/dashboard/api/channel_rules",
  adminChannelState: "/dashboard/api/state",
  adminUser: "/dashboard/api/user/",
  adminWhatsappTemplate: "/dashboard/api/whatsapp_templates/",
  downloadAccountCallingReport: "/dashboard/api/download-calling-report/",
  downloadDailyReport: "/dashboard/api/downlaod-daily-report/",
  downloadDailyReportN: "/dashboard/api/download-daily-report-new/",
  updateBatchConvo: "dashboard/api/update_batch_convo/",
  updateBatchCampaign: "dashboard/api/update_campaign/",
  downloadPrelitiBatchReport: "dashboard/api/batch_pre_leti_download/",
  PreLitiBatchData: "/dashboard/api/batch_pre_leti_get/",
  manualCallInitiate: "/call/api/manual_call_initiate/",
  dataWiseBatchData: "/dashboard/api/datewise-batch-data/",
  riskAssessment: "/dashboard/api/risk-assessment/",
  dispositionWiseReport: "/dashboard/api/get-disposition-wise-report/",
  admindispositionWiseReport: "/dashboard/api/get-disposition-wise-report-admin/",
  totalCollection: "/dashboard/api/total-collection-ptp/",
  prelitiBatch: "/pre_litigation/api/pre_litigation_batch_new/",
  prelitiNotice: "/pre_litigation/api/notice_new/",
  commonActivity: "dashboard/api/get_common_activity/",
  unknownCust: "dashboard/api/agent-wise-unknown-customers/",
  check: "dashboard/api/agent-disposition-collection-record/",
  callReport: "dashboard/api/call-report/",
  recordList: "call/api/call-record-listing/",
  adminData: "dashboard/api/admin-panel-data/",
  clientData: "dashboard/api/get_batch_for_reports/",
  agentLanguage: "dashboard/api/agent-langauge/",
  whatsappTemplate: "dashboard/api/agent-wise-template/",
  messageTemplate: "dashboard/api/message-template/",
  createCampaign: "dashboard/api/admin-campaign/",
  campaignPreset: "/dashboard/api/admin-campaign-pre/",
  campSeed: "/dashboard/api/admin-campaign-seed/",
  agentDispCollection: "dashboard/api/agent-disposition-collection/",
  agentCalls: "dashboard/api/admin-calls/",
  variableCalls: "notice/api/template-variable/",
  agentChats: "dashboard/api/admin-chats/",
  mailConvo: "freshdesk/api/email_webhook/",
  clientAPI: "dashboard/api/client_new/",
  batchWiseAnalysis: "dashboard/api/batch_wise_analysis/",
  agingReportAnalysis: "dashboard/api/aging_report_analysis/",
  bulkReportD: "dashboard/api/bulk_report_download/",
  firBatch: "fir/api/fir_batch/",
  fetchCities: "pre_litigation/api/getCities/",
  fetchStates: "/fir/api/get_state_list/",
  fetchDistrict: "/fir/api/get_district_list/",
  fetchPoliceStation: "/fir/api/get_police_station_list/",
  FIRComplaints: "fir/api/complaints/",
  reset_campaign: "/dashboard/api/reset_campaign/",
  language_wise_breakup: "/dashboard/api/language_wise_breakup/",
  changeSpeed: "/dashboard/api/changeSpeed/",
  update_campaign_status: "/dashboard/api/update_campaign_status/",
  downloadTrack: "dashboard/api/Conciliation_Camp/",
  productsProcessWise: "dashboard/api/getProducts/",
  trackingStatus: "pre_litigation/api/getScans/",
  humanTemp: "dashboard/api/human_templates/",
  ivrTemp: "dashboard/api/ivr_template_new/",
  emailTemp: "dashboard/api/email_templates/",
  dailyReportSettings: "dashboard/api/dailyreport-setting/",
  actualDisp: "dashboard/api/actual_analysis/",
  touchedAnalysis: "dashboard/api/touched_analysis/",
  agentWiseAnalysis: "dashboard/api/agent_wise_analysis/",
  addNewAgent: "/dashboard/api/agents",
  getAgentData: "/dashboard/api/agents/",
  dispWiseAnalysis: "dashboard/api/disposition_wise_analysis/",
  downloadTrackingReport: "/pre_litigation/api/download_tracking_report/",
  conciliationCamp: "/dashboard/api/Conciliation_Camp/",
  getDispositionData: "/dashboard/api/agent-disposition-collection/",
  batchProducts: "/dashboard/api/client-product/",
  getCategory: "dashboard/api/get-category/",
  generateReport: "dashboard/api/test_report_download/",
  agentLanguageN: "/dashboard/api/agent_languages/",
  previewReport: "/dashboard/api/preview_report/",
  recentReport: "dashboard/api/recent_reports/",
  singleNotice: "/pre_litigation/api/single_legal_notice/",
  singleFir: "/fir/api/file_single_fir_from_collection/",
  batchDataReport: "/dashboard/api/get_batch_for_reports/",
  whatsappTemplateGet: "/notice/api/whatsapp-template/",
  smsTemplateGet: "/message91/api/sms-template/",
  getCatelist: "/notice/api/template-categories/",
  getVariablesList: "/notice/api/template-variable/",
  getemailtemp: "/notice/api/email-template/",
  refreshmsg91: "/message91/api/message91-status-check/",
  senderIdsms: "/notice/api/sms-sender-ids/",
  testmessageTemp: "/message91/api/message91-template-test/",
  testemail: "/notice/api/email-template-test/",
  dispatchList: "/pre_litigation/api/download_disptach_list_report/",
  getDistrict: "/fir/api/get_districts/",
  getPoliceStation: "/fir/api/get_police_stations/",
  downloadFIRData: "/fir/api/fir_report_download/",
  postBulk: "/fir/api/complaints/",
  noticetemplist: "/notice/api/file-template/",
  headerFooterList: "/notice/api/header-footer-list",
  crossCheckReport: "dashboard/api/crosscheck_report_download/",
  similarNameReport: "/dashboard/api/similar_name_report/",
  dispositionAccountData: "/dashboard/api/get-details-loan-contact/",
  emailReply: "/freshdesk/api/get_email_reply/",
  dispmap: "/dashboard/api/get_product_mappings/",
  legalStatus: "/notice/api/file-template/",
  addNewCat: "/notice/api/template-categories/",
  previewApi: "/client_panel/api/template_map/",
  getDailyReport: "/dashboard/api/get_daily_report_history",
  markasArchive: "/archive_panel/api/set-to-archive/",
  downloadArchiveApi: "/client_panel/api/download-model-data",
};

export const fetchAddNewCat = (body) => apiPOST("/notice/api/template-categories/", body);

export const fetchlegalStatus = (body, id) => apiPATCH(`/notice/api/file-template/${id}`, body);

export const fetchCampaignReport = (params = { campaign_id: 0 }) => apiGET("/dashboard/api/campaign_report/", params);

export const crossCheckReport = (body, params) => apiPOST("/dashboard/api/bulk_report_download/", body, params);

export const updateAgentStatus = (body) => apiPOST("/dashboard/api/update_agent_status/", body);

export const postBulkData = (data) => apiPOST("/fir/api/complaints/", { complaints: data });

export const fileFirMissing = (params) => apiGET("/fir/api/hit_file_fir/", params);

export const startServingToBorrowerCampaign = (params) => apiGET("/fir/api/start_serving_to_borrower_campaign/", params);

// expxort const downloadNotWorkingBatchFile = (params) => apiGET("/fir/api/download_not_working_batch_file/", params);

export const downloadNotWorkingBatchFile = (client_id, batchId) =>
  `${getBaseUrl().baseUrl}/fir/api/download_not_working_batch_file/?client_id=${client_id}&batch_id=${batchId}`;

export const updateActivityStatus = (id) => apiPOST("/dashboard/api/ca_status_update/", { agent_id: id });

export const fetchProductNew = (sub_process) => apiGET("/dashboard/api/get_products_for_reports/", { sub_process });

export const proceedToCompressedFile = (params) => apiGET("/dashboard/api/compressed_bulk_reports/", params);

export const fetchRecentReports = (params) => apiGET("/dashboard/api/recent_reports/", params);

export const fetchPreviewReport = (params) => apiGET("/dashboard/api/preview_report/", params);

export const fetchLangByAgents = (agnt) => apiPOST("/dashboard/api/agent_languages/", { agent: agnt });

export const generateReportN = (params) => apiGET("/dashboard/api/test_report_download/", params);

export const fetchCategory = (params) => apiGET("/dashboard/api/get-category/", params);

export const fetchDispWiseAnalysis = (params) => apiGET("/dashboard/api/disposition_wise_analysis/", params);

export const fetchAgentDispAnalysis = (params) => apiGET("/dashboard/api/agent_wise_analysis/", params);

export const fetchTouchedDispAnalysis = (params) => apiGET("/dashboard/api/touched_analysis/", params);

export const fetchActualDispAnalysis = (params) => apiGET("/dashboard/api/actual_analysis/", params);

export const fetchIVRTemplate = async (client_id, category) => {
  try {
    const res = await axios.get("/dashboard/api/ivr_template/", { params: { client_id, category } });
    return res;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchEmailTemplate = async (client_id) => {
  try {
    const res = await axios.get("/dashboard/api/email_templates/", { params: { client_id } });
    return res;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchHumanTemplate = async (client_id) => {
  try {
    const res = await axios.get("/dashboard/api/human_templates/", { params: { client_id } });
    return res;
  } catch (error) {
    handleApiError(error);
  }
};

export const getTrackingStatus = async (trackingId) => {
  try {
    const res = await axios.get("pre_litigation/api/getScans/", { params: { trackingId } });
    return res;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchProducts = (id, pro) => apiGET("/dashboard/api/getProducts/", { client: id, process: pro });

export const downloadTrackFile = (params) => apiGET("/dashboard/api/Conciliation_Camp/", params);

export const fetchFIRComplaints = (params) => apiGET("/fir/api/complaints/", params);

export const updateCollectionStatus = (body) => apiPOST("/dashboard/api/batch_new/", body);

export const fetchCities = (sub_process) => apiGET("/pre_litigation/api/getCities/", { sub_process });

export const fetchStates = (client_id, batch_id) => apiGET("/fir/api/get_state_list/", { client_id, batch_id });

export const fetchDistrict = (client_id, batch_id) => apiGET("/fir/api/get_district_list/", { client_id, batch_id });

export const getDistrictData = (state_id) => apiGET("/fir/api/get_districts/", { state_id });

export const getPoliceStationData = (district_id) => apiGET("/fir/api/get_police_stations/", { district_id });

export const fetchPoliceStation = (client_id, batch_id) => apiGET("/fir/api/get_police_station_list/", { client_id, batch_id });

export const fetchFirBatches = (params) => apiGET("/fir/api/fir_batch/", params);

export const postPreLitiFIRBatchData = async (formData) => {
  try {
    const res = await axios.post("/fir/api/fir_batch/", formData);
    return res;
  } catch (error) {
    handleApiError(error);
  }
};

export const similarNameReportUpdate = async (body) => {
  try {
    const res = await axios.post("/dashboard/api/similar_name_report/", body);
    return res;
  } catch (error) {
    handleApiError(error);
  }
};

export const downloadBulkReports = async (body, is_file) => {
  try {
    let params = { is_file };

    let myAxios = axios.create({
      paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: "repeat" }),
    });
    const res = await myAxios.post("/dashboard/api/bulk_report_download/", body, { params });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getBulkReports = async (params) => {
  try {
    let myAxios = axios.create({
      paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: "repeat" }),
    });
    const res = await myAxios.get("/dashboard/api/bulk_report_download/", { params });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getAgingReportAnalysisData = (type, batch_id) => apiGET("/dashboard/api/aging_report_analysis/", { type, batch_id });

export const getBatchWiseAalysisData = (type, product, batch_id) => apiGET("/dashboard/api/batch_wise_analysis/", { type, batch_id, product });

export const fetchClient = (params) => apiGET("/dashboard/api/client/", params);

export const fetchTemplates = (params) => apiGET("/dashboard/api/get_whatsapp_templates/", params);

export const fetchTemplateData = (body) => apiPOST("/dashboard/api/get_whatsapp_templates/", body);

export const fetchAgentCalls = (params) => apiGET("/dashboard/api/admin-calls/", params);

export const fetchVariableCalls = (params) => apiGET("/notice/api/template-variable/", params);

export const fetchAgentChats = (params) => apiGET("/dashboard/api/whatspp_chat_name/", params);

export const fetchAgentCallAndDisp = (params) => apiGET("/dashboard/api/agent-disposition-collection/", params);

export const fetchWhatsappTemplate = async (client_id, category) => {
  try {
    const res = await axios.get("/dashboard/api/agent-wise-template/", { params: { client_id, category } });
    return res;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchMessageTemplate = async (client_id, category) => {
  try {
    const res = await axios.get("/dashboard/api/message-template/", { params: { client_id, category } });
    return res;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchCampaign = (params) => apiGET("/dashboard/api/admin-campaign/", params);
export const createCampaignP = (body) => apiPOST("/dashboard/api/admin-campaign/", body);

export const fetchPresetCampaign = (params) => apiGET("/dashboard/api/admin-campaign-pre/", params);
export const createCampaignPreset = (body) => apiPOST("/dashboard/api/admin-campaign-pre_new/", body);
export const updateCampaignPreset = (body) => apiPOST("/dashboard/api/admin-campaign-pre_new/", body);
export const presetDelete = (body) => apiDELETE("/dashboard/api/admin-campaign-pre_new/", body);

export const campaignPresetSeed = (body) => apiPOST("/dashboard/api/admin-campaign-seed/", body);

export const fetchClientBatch = (client_id, process, notice_type) => apiGET("/dashboard/api/get_batch_for_reports/", { client_id, process, notice_type });

export const fetchBatchDataReport = (client_id, process) => apiGET("/dashboard/api/get_batch_for_reports/", { client_id, process });

export const fetchBatchProducts = (params) => apiGET("/dashboard/api/client-product/", params);

export const fetchAdminData = (params) => apiGET("/dashboard/api/admin-panel-data/", params);

export const fetchCommonActivity = (user_id) => apiGET("/dashboard/api/get_common_activity/", { user_id });

export const fetchUnknownStatus = (params) => apiGET("/dashboard/api/agent-wise-unknown-customers/", params);

export const getPreLitiNoticeData = (params) => apiGET("/pre_litigation/api/notice/", params);

export const getDispMapping = (params) => apiGET("/dashboard/api/get_product_mappings/", params);

export const getPreLitiBatchData = (params) => apiGET("/pre_litigation/api/pre_litigation_batch/", params);

export const putPreLitiBatchData = (params) => apiPOST(`/pre_litigation/api/pre_litigation_batch_new/`, params);

export const postPreLitiBatchData = async (formData) => {
  try {
    const res = await axios.post("/pre_litigation/api/pre_litigation_batch/", formData);
    return res;
  } catch (error) {
    handleApiError(error);
  }
};

export const getDataWiseBatchData = async (duration, ytd) => {
  const params = duration === "YTD" ? { duration, date_from: ytd.from, date_to: ytd.to } : { duration };
  return apiGET("/dashboard/api/datewise-batch-data/", params);
};

export const getRiskAssessment = async (type, product, batch_id) => apiGET("/dashboard/api/risk-assessment/", { type, product, batch_id });

export const getDispositionWiseReport = async (type, product, batch_id) => apiGET("/dashboard/api/get-disposition-wise-report/", { type, product, batch_id });

export const getadminDispositionWiseReport = async (type, product, batch_id) =>
  apiGET("/dashboard/api/get-disposition-wise-report-admin/", { type, product, batch_id });

export const getDispositionCallReport = async (params) => apiGET("/dashboard/api/call-report/", params);

export const logout = async (params) => apiGET("/dashboard/api/agent_logout/", params);

export const getDispCallRecordListing = async (params) => apiGET("/call/api/call-record-listing/", params);

export const getLiveFeed = async (ordering, page) => apiGET("/dashboard/api/live-feed-data/", { ordering, page });

export const getTotalCollection = async (params) => apiGET("/dashboard/api/total-collection-ptp/", params);

export const postManualCallInitiate = async (to_number) => apiPOST("/call/api/manual_call_initiate/", { to_number });

export const fetchCollectionBatch = async (params) => apiGET("/dashboard/api/batch/", params);

export const fetchArchiveBatch = async (params) => apiGET("/archive_panel/api/set-to-archive/", params);
export const markAsArchiveStatus = (body) => apiPOST("/archive_panel/api/set-to-archive/", body);

export const markArchiveAdmin = (body) => apiPOST("/archive_panel/api/data_all_archive/", body);

export const getAgentData = (id) => apiGET(`/dashboard/api/agents/${id}`, {});
export const addNewAgent = (body) => apiPOST("/dashboard/api/agents", body);

export const fetchWhatsappData = (number) => apiGET(`/dashboard/api/get_wati_conversation/`, { number });

export const patchBatchCampaign = (batch_ids, date, dispositions, channels) =>
  apiPOST("/dashboard/api/update_campaign/", {
    batch_ids,
    date,
    dispositions,
    channels,
  });

export const patchBatchConvo = async (batch_id, data, type) => {
  try {
    let myData = {
      batch_id,
      disposition: "SW",
      type: "Not Connected",
      channels: { ...data },
    };
    let myParams = { batch_id };
    const res = await axios.post(apiUrl.updateBatchConvo, myData, {
      params: myParams,
    });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getBatchConvo = async (batch_id) => {
  try {
    let myParams = { batch_id };
    const res = await axios.get(apiUrl.updateBatchConvo, { params: myParams });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const downloadAccountCallingReport = async (batch_id, mobile_number) => {
  try {
    let myParams = { batch_id, mobile_number };
    const res = await axios.get(apiUrl.downloadAccountCallingReport, {
      params: myParams,
    });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const downloadBatchCallingReport = async (batch_id) => {
  try {
    let myParams = { batch_id };
    const res = await axios.get(apiUrl.downloadAccountCallingReport, {
      params: myParams,
    });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const downloadArchiveValue = async (params) => {
  try {
    const res = await axios.get(apiUrl.downloadArchiveApi, {
      params,
    });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const sendWhatsappMessage = async (mobileNumber, text) => {
  try {
    let myData = {
      number: mobileNumber,
      message: text,
    };
    // let myData = new FormData()
    // myData.append(   "number",mobileNumber)
    // myData.append(   "message",text)
    const res = await axios.post(apiUrl.sendWaMessage, myData);
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const findCustomer = async (customer_name, mobileNumber, lrn) => {
  try {
    let myData = {
      customer_name: customer_name,
      mob: mobileNumber,
      lrn: lrn,
    };
    const res = await axios.post(apiUrl.findCustomer, myData);
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchAccountData = async (id) => {
  try {
    const res = await axios.get(apiUrl.collectionAccounts, {
      params: { customer_id: id },
    });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchAccountsPageData = async (SearchParameter) => {
  try {
    const res = await axios.get(apiUrl.collectionAccounts, {
      params: SearchParameter,
    });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchReportData = async (param) => {
  try {
    const res = await axios.get(apiUrl.getDailyReport, { params: param });
    if (res) {
      return res.data;
    }
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchDispositionData = async (mobileNumber) => {
  try {
    const myParams = { customer_mobile_number: mobileNumber };
    const res = await axios.get(apiUrl.disposition, { params: myParams });
    return res.data.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchDispositionAccountCaseData = async (mobileNumber, loanNo, batch_id) => {
  try {
    const myParams = {
      loan_account_no: loanNo,
      contact_no: mobileNumber,
      batch_id,
    };
    const res = await axios.get(apiUrl.dispositionAccountData, {
      params: myParams,
    });
    return res.data.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchEmailReply = async (email) => {
  try {
    const myParams = { mail: email };
    const res = await axios.get(apiUrl.emailReply, { params: myParams });
    return res;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchCallData = async (mobileNumber) => {
  try {
    //8423050982

    const myParams = { to_number: mobileNumber };
    const res = await axios.get(apiUrl.calls, { params: myParams });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchMailData = async (para) => {
  try {
    const res = await axios.get(apiUrl.mailConvo, { params: para });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchSmsData = async (mobileNumber) => {
  try {
    let smsUrl = apiUrl.sms + mobileNumber;
    const res = await axios.get(smsUrl);

    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const postDispositionData = async (dispoData) => {
  try {
    const res = await axios.post(apiUrl.postDispoData, dispoData);
    return res;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchOnGoingCurrectCall = async (mobileNumber) => {
  try {
    const myParams = { agent_number: mobileNumber };
    const res = await axios.get(apiUrl.checkCurrentUser, { params: myParams });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const postBatchData = async (formData) => {
  try {
    const res = await axios.post(apiUrl.uploadBatch, formData);
    return res;
  } catch (error) {
    handleApiError(error);
  }
};

export const getAgentChat = async (mobileNumber) => {
  try {
    // /7678690687
    const myParams = {
      agent_number: mobileNumber,
    };
    const res = await axios.get(apiUrl.agentChats, { params: myParams });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchDashboardDateWise = async (dat) => {
  try {
    const myParams = {
      data: dat,
    };
    const res = await axios.get(apiUrl.dashboardDateWiseData, {
      params: myParams,
    });

    return res;
  } catch (error) {
    handleApiError(error);
  }
};
export const fetchDashboardLiveFeed = async () => {
  try {
    const myParams = { page: 1 };
    const res = await axios.get(apiUrl.dashboardLiveData, { params: myParams });

    return res.data.results;
  } catch (error) {
    handleApiError(error);
  }
};
export const fetchDashboardDisposition = async () => {
  try {
    const res = await axios.get(apiUrl.dashboardDispositionData);

    return res;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchAdminReport = async () => {
  try {
    const res = await axios.get(apiUrl.adminReport);

    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};
export const fetchAdminBatchReport = async (id) => {
  try {
    const myParams = { batch_id: id };
    const res = await axios.get(apiUrl.adminBatchReport, { params: myParams });

    return res.data.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchAdminChannels = async () => {
  try {
    const res = await axios.get(apiUrl.adminChannel);
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const patchAdminChannels = async (data) => {
  try {
    await axios.put(apiUrl.adminChannel + "/" + 1, data);
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchAdminAgents = async (id) => {
  try {
    const res = await axios.get(apiUrl.adminAgent, {
      params: { client_id: id },
    });

    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const patchAdminAgents = async (id, data) => {
  try {
    let res = await axios.post(apiUrl.adminAgent + "/" + id, data);
    return res;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchAdminChannelRules = async () => {
  try {
    const res = await axios.get(apiUrl.adminChannelRules);

    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const patchAdminChannelRules = async (id, data) => {
  try {
    await axios.put(apiUrl.adminChannelRules + "/" + id, data);
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchAdminState = async () => {
  try {
    const res = await axios.get(apiUrl.adminChannelState);

    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const patchAdminState = async (id, data) => {
  try {
    await axios.put(apiUrl.adminChannelState, data);
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchAdminUser = async () => {
  try {
    const res = await axios.get(apiUrl.adminUser);

    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const patchAdminUser = async (id, data) => {
  try {
    await axios.put(apiUrl.adminUser + "/" + id, data);
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchAdminWhatsappTemplate = async () => {
  try {
    const res = await axios.get(apiUrl.adminWhatsappTemplate);

    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const patchAdminWhatsappTemplate = async (id, data) => {
  try {
    await axios.put(apiUrl.adminWhatsappTemplate + "/" + id, data);
  } catch (error) {
    handleApiError(error);
  }
};

export const downloadPreBatchReport = async () => {
  try {
    // let myParams = { batch_id }
    const res = await axios.get(apiUrl.downloadPrelitiBatchReport);
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchPreLitiBatchData = async () => {
  try {
    const res = await axios.get(apiUrl.PreLitiBatchData);
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const downloadCallReport = async (dt, id) => {
  try {
    const myPara = {
      date: dt,
      client: id,
      follow_up: 1,
    };
    const res = await axios.get(apiUrl.downloadDailyReportN, {
      params: myPara,
    });

    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const language_wise_breakup = async (id) => {
  try {
    const res = await axios.get(apiUrl.language_wise_breakup + "?campaign_id=" + id);

    return res;
  } catch (error) {
    handleApiError(error);
  }
};

export const reset_campaign = async (id) => {
  try {
    let campaign_id = { campaign_id: id };
    const res = await axios.post(apiUrl.reset_campaign, campaign_id);

    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getAgentLanguages = async () => {
  try {
    const res = await axios.get("/dashboard/api/language_list/");
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const runCrons = async (command) => {
  try {
    const params = { command };
    return await axios.get("/dashboard/api/run_crons/", { params });
  } catch (error) {
    handleApiError(error);
  }
};

export const lastReportUpdate = async (body) => {
  try {
    return await axios.post("/dashboard/api/update_disposition_by_file/", body);
  } catch (error) {
    handleApiError(error);
  }
};

export const changeSpeed = async (id, whatsAppSpeed, smsSpeed, ivrSpeed, humanCallSpeed, emailSpeed) => {
  try {
    let changeData = {
      campaign_id: id,
      channel_data: [
        { channel: "whatsapp", speed: whatsAppSpeed },
        { channel: "sms", speed: smsSpeed },
        { channel: "ivr", speed: ivrSpeed },
        { channel: "human", speed: humanCallSpeed },
        { channel: "email", speed: emailSpeed },
      ],
    };
    const res = await axios.post(apiUrl.changeSpeed, changeData);

    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};
export const pause_campaign_status = async (id, status) => {
  try {
    let changeData = {
      campaign_id: id,
      status: status,
    };
    const res = await axios.post(apiUrl.update_campaign_status, changeData);

    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getDailyReportSettings = async (id = 1) => {
  try {
    const myPara = {
      client_id: id,
    };
    const res = await axios.get(apiUrl.dailyReportSettings, { params: myPara });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const postDailyReportSettings = async (data) => {
  try {
    const res = await axios.post(apiUrl.dailyReportSettings, data);
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};
export const downloadDailyReportSettings = async (params) => {
  try {
    const res = await axios.get(apiUrl.downloadDailyReport, { params: params });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};
export const fetchDownloadTrackingReport = async (params) => {
  try {
    const res = await axios.get(apiUrl.downloadTrackingReport, {
      params: params,
    });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};
export const fetchFIRDownloadTrackingReport = async (params) => {
  try {
    const res = await axios.get(apiUrl.downloadFIRData, { params: params });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};
export const fetchConciliationCamp = async (params) => {
  try {
    const res = await axios.get(apiUrl.conciliationCamp, { params: params });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchDispatchList = async (params) => {
  try {
    const res = await axios.get(apiUrl.dispatchList, { params: params });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};
export const fetchdispositionsData = async () => {
  try {
    const res = await axios.get(apiUrl.getDispositionData);
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const sendSingleLegalNotice = async (data) => {
  try {
    const body = data;
    const res = await axios.post(apiUrl.singleNotice, body);
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};
export const sendSingleLegalFir = async (data) => {
  try {
    const body = data;
    const res = await axios.post(apiUrl.singleFir, body);
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};
export const fetchwhatsAppTemplate = async (params) => {
  try {
    // let myParams = { batch_id }
    const res = await axios.get(apiUrl.whatsappTemplateGet, { params: params });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const selectedTemplate = (type) => {
  switch (type) {
    case "email":
      return apiUrl.getemailtemp;
    case "sms":
      return apiUrl.smsTemplateGet;
    case "whatsapp":
      return apiUrl.whatsappTemplateGet;

    default:
      return "no";
  }
};

export const setStatusTemplate = async ({ type, id, status }) => {
  const selectedTemplateUrl = `${selectedTemplate(type)}${id}/`;
  try {
    const res = await axios.patch(selectedTemplateUrl, { status: status });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};
export const fetchsmsAppTemplate = async (params) => {
  try {
    const res = await axios.get(apiUrl.smsTemplateGet, { params: params });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const postsmsAppTemplate = async (data) => {
  try {
    const body = data;
    const res = await axios.post(apiUrl.smsTemplateGet, body);
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const postwhatsAppTemplate = async (data) => {
  try {
    const body = data;
    const res = await axios.post(apiUrl.whatsappTemplateGet, body);
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const postAddVaraiable = async (data) => {
  try {
    const body = data;
    const res = await axios.post(apiUrl.variableCalls, body);
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};
export const fetchcategoryTemplate = async () => {
  try {
    // let myParams = { batch_id }
    const res = await axios.get(apiUrl.getCatelist);
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchvariablesTemplate = async (process, variable, variablefor) => {
  try {
    let myParams = {
      process: process,
      variable: variable,
      variable_for: variablefor,
    };
    const res = await axios.get(apiUrl.getVariablesList, { params: myParams });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchLeagalNoticeTemplate = async (params) => {
  try {
    const res = await axios.get(apiUrl.noticetemplist, { params: params });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchemailAppTemplate = async (params) => {
  try {
    const res = await axios.get(apiUrl.getemailtemp, { params: params });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const postemailAppTemplate = async (data) => {
  try {
    const body = data;
    const res = await axios.post(apiUrl.getemailtemp, body);
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const postlegalNoticeAppTemplate = async (data) => {
  try {
    const body = data;
    const res = await axios.post(apiUrl.noticetemplist, body);
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchheaderFooterTemplate = async (channel, type) => {
  try {
    let myParams = {
      channel: channel,
      type: type,
    };
    const res = await axios.get(apiUrl.headerFooterList, { params: myParams });
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const patchemailAppTemplate = async ({ id, body }) => {
  try {
    const res = await axios.patch(apiUrl.getemailtemp + `${id}/`, body);
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const patchlegalNoticeAppTemplate = async (id, data) => {
  try {
    const body = data;
    const res = await axios.patch(apiUrl.noticetemplist + `${id}/`, body);
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};
export const patchsmsAppTemplate = async (id, data) => {
  try {
    const body = data;
    const res = await axios.patch(apiUrl.smsTemplateGet + `${id}/`, body);
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const patchWatiAppTemplate = async (id, data) => {
  try {
    const body = data;
    const res = await axios.patch(apiUrl.whatsappTemplateGet + `${id}/`, body);
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const refreshsmsAppTemplate = async (id) => {
  try {
    const res = await axios.get(apiUrl.refreshmsg91 + `${id}/`);
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};
export const senderIdsmsAppTemplate = async () => {
  try {
    const res = await axios.get(apiUrl.senderIdsms);
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const previewSmsAppTemplate = async (body) => {
  try {
    const res = await axios.post(apiUrl.previewApi, body);
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const updateFIRComplaints = async (SearchParameter, body) => {
  try {
    const res = await axios.put(apiUrl.FIRComplaints + "?complaint_id=" + SearchParameter.complaint_id, body);
    return res.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const testmsgAppTemplate = (body) => apiPOST("/message91/api/message91-template-test/", body);

export const testemailAppTemplate = (body) => apiPOST("/notice/api/email-template-test/", body);

export const downloadBatchData = (body) => apiPOST("/client_panel/api/download-batch-wise-data/", body);

export const senderIdemailAppTemplate = (params) => apiGET("/notice/api/email-sender-ids/", params);

export const fetchWatiTemplate = (params) => apiGET("/notice/api/update-wati-template/", params);

export const watiContactListTemplate = (params) => apiGET("/notice/api/wati-contact-list/", params);

export const getVerifyDataFxn = (batch_id) => apiGET("/archive_panel/api/data_verify/", { batch_id });

export const testwhatsAppTemplate = (body) => apiPOST("/notice/api/wati-template-test/", body);

export const sendLetterEmail = (params) => apiGET("/dashboard/api/send_letter_email/", params);

export const getViewDailySettlementData = (params) => apiGET("/dashboard/api/view_daily_settlement_data/", params);

export const updateDailySettlementData = (params, id) => apiPOST(`/dashboard/api/view_daily_settlement_data_new/${id}/`, params);

export const getDigitalManagementReport = (params) => apiGET(`/dashboard/api/digital_managemenet_report/`, params);

export const getRecoveryRateManagementReport = (params) => apiGET(`/dashboard/api/recovery_rate_management_report/`, params);

export const putCampaignBulkActions = (params) => apiPOST(`/campaigns/api/campaign_bulk_actions/`, params);

export const getDownloadCallingReport = (params) => apiGET(`/dashboard/api/download_calling_report/`, params);

// Client Settings
export const getClientFeature = (params) => apiGET(`/dashboard/api/client_feature/`, params);
export const putClientFeature = (params) => apiPOST(`/dashboard/api/client_feature_new/`, params);
export const postClientFeature = (body) => apiPOST(`/dashboard/api/client_feature/`, body);

export const postUploadWhatsappStatus = (body) => apiPOST(`/pre_litigation/api/upload_whatsapp_status/`, body);

export const getProductDpdAllocation = (params) => apiGET(`/dashboard/api/get_product_dpd_allocation/`, params);

export const getDispositions = (params) => apiGET(`/dashboard/api/get_dispositions/`, params);

export const postDownloadBulkNoticeReport = (body) => apiPOST(`/dashboard/api/download_bulk_notice_report/`, body);

export const getCollectionReportProductWise = (params) => apiGET(`/dashboard/api/collection_report_product_wise/`, params);

export const getDownloadPtpReport = (params) => apiGET(`/dashboard/api/download_ptp_report/`, params);

export const postSendSetlVerificationMessage = (body) => apiPOST(`/dashboard/api/send_setl_verification_message/`, body);

export const postSendReminderToLender = (body) => apiPOST(`/dashboard/api/send_reminder_to_lender/`, body);

export const getSendPaymentFollowup = (params) => apiGET(`/dashboard/api/send_payment_followup/`, params);

// download media URL
export const downloadMediaUrl = (url) => `${getBaseUrl().baseUrl}/dashboard/api/download_media/?url=${url}`;

// download agent_wise_call_report
export const downloadAgentWiseCallReport = (client_id) => `${getBaseUrl().baseUrl}/dashboard/api/agent_wise_call_report/?client_id=${client_id}`;

export const getSendAgentMgmtEmails = (params) => apiGET(`/dashboard/api/send_agent_mgmt_emails/`, params);

export const downloadFirCopiesZip = (params) => apiGET(`/fir/api/download_fir_copies_zip/`, params);

export const getAgentWiseCallTimeManagementReport = (params) => apiGET(`/dashboard/api/agentwise_call_time_management_report/`, params);
export const getAgentWiseAverageTargetManagementReport = (params) => apiGET(`/dashboard/api/agentwise_average_target_management_report/`, params);
export const getAgentWiseCollectionTargetManagementReport = (params) => apiGET(`/dashboard/api/agentwise_collection_target_management_report/`, params);
export const getAgentWiseCallTargetManagementReport = (params) => apiGET(`/dashboard/api/agentwise_call_target_management_report/`, params);
export const getAgentWiseCollectionManagementReport = (params) => apiGET(`/dashboard/api/agentwise_collection_management_report/`, params);

export const getRiskFinalSummaryManagementReport = (params) => apiGET(`/dashboard/api/risk_final_summary_management_report/`, params);

export const postSendLegalNotice = (body) => apiPOST(`/dashboard/api/send_legal_notice/`, body);

export const getNoticePdfLink = (params) => apiGET(`/dashboard/api/get_notice_pdf_link/`, params);

export const getAgentApplicationSummary = (params) => apiGET(`/dashboard/api/agent_application_summary/`, params);
export const getAgentApplication = (params) => apiGET(`/dashboard/api/admin_agent_application/`, params);

export const getActivateAgentApplication = (params) => apiGET(`/dashboard/api/activate_agent_application/`, params);
export const getSendAgentApplicationMgmtEmails = (params) => apiGET(`/dashboard/api/send_agent_application_mgmt_emails/`, params);

export const getAgentJobs = (params, cancelToken = null) => apiGET(`/dashboard/api/agent_jobs/`, params, cancelToken);
export const postAgentJobs = (body, params) => apiPOST(`/dashboard/api/agent_jobs/`, body, params);
export const putAgentJobs = (body, params) => apiPOST(`/dashboard/api/agent_jobs_new/`, body, params);
export const deleteAgentJobs = (params) => apiPOST(`/dashboard/api/agent_jobs_new/`, undefined, params);

export const getCallQuality = (params) => apiGET(`/call/api/call-quality-listing/`, params);
export const putCallQuality = (call_id, params) => apiPOST(`/call/api/update-call-quality/${call_id}`, params);

export const postUploadBulkNotices = (body) => apiPOST(`/dashboard/api/upload_bulk_notices/`, body);

export const getActionFinalSummaryManagementReport = (params) => apiGET(`/dashboard/api/action_final_summary_management_report/`, params);

export const getDailyThirdpartyWebhookStatus = (params) => apiGET(`/dashboard/api/daily_thirdparty_webhook_status/`, params);

export const postDownloadMissingPaymentData = (body) => apiPOST(`/dashboard/api/download_missing_payment_data/`, body);

export const getMailHistory = (params = { customer_mobile_number: null }) => apiGET("/freshdesk/api/email_webhook/", params);

export const getEmailReply = (params = { mail: null }) => apiGET("/freshdesk/api/get_email_reply/", params);

export const getWhatsappHistory = (params = { number: null }) => apiGET("/dashboard/api/get_wati_conversation/", params);

export const getSmsHistory = (params = { mobile_number: null }) =>
  apiGET(`/message91/api/get_message_converjations_by_customer_mobile_number/${params?.mobile_number}`, {});

export const getChatGptPrompt = (params) => apiGET("/dashboard/api/get_chat_gpt_prompt/", params);

export const getGupShupAccount = (params) => apiGET("/dashboard/api/gupshup-account/", params);

export const postBulkProspectiveCandidate = (body) => apiPOST("/dashboard/api/bulk_prospective_candidate/", body);

export const getCandidateGupshupConversation = (params) => apiGET("/dashboard/api/get_candidate_gupshup_conversation/", params);
export const getGupshupConversation = (params) => apiGET("/dashboard/api/get_gupshup_conversation/", params);
export const getCollectionManagerConversation = (params) => apiGET("/pre_litigation/api/get_collection_manager_conversation", params);
export const postSendSessionMessage = (body) => apiPOST("/pre_litigation/api/send_session_message/", body);
export const postSendmessagetoCollectionManager = (body) => apiPOST("/pre_litigation/api/send_message_to_collection_manager/", body);

export const getWhatsappTemplateData = (params) => apiGET("/dashboard/api/get_whatsapp_template_data/", params);
