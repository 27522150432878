import { IoLogoWhatsapp } from "react-icons/io";
import { getValueIfObject, parseJson, snakeToNormal } from "../../helpers";
import AnalysisCard from "./analysis-card";
import useBorrowerInfo from "../../store";
import { Fragment, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import WhatsappMsg from "../../../../../Components/WhatsappMsg";
import useAuthStore from "../../../../../store/authStore";
import { IoCloseCircle } from "react-icons/io5";

export default function WhatsappResponse({ show = false }) {
  const [isOpen, setIsOpen] = useState(false);
  const [showChats, setShowChats] = useState(show ? true : false);

  const userData = useAuthStore((state) => state.userData);
  const data = useBorrowerInfo((state) => state.data);
  const analysis = useBorrowerInfo((state) => state.analysis);

  const toggle = () => setIsOpen((prev) => !prev);

  useEffect(() => {
    if (show) toggle();
  }, [show]);

  return (
    <>
      {!show && (
        <AnalysisCard
          title="Whatsapp Response"
          tag={analysis?.whatsapp_tag}
          content={getValueIfObject(analysis?.whatsapp_disposition, "scenario_name")}
          icon={IoLogoWhatsapp}
          onClick={toggle}
        />
      )}

      <Offcanvas style={{ width: "28rem", paddingTop: "0.75rem" }} show={isOpen} onHide={toggle} backdropClassName="opacity-0" placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title className="d-flex justify-content-between align-items-center w-100 px-2">
            <div className="fw-600 fs-4">WhatsApp</div>
            <button onClick={toggle} type="button" className="bg-white border-0">
              <IoCloseCircle fill="#999999" size={30} />
            </button>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!analysis ? (
            <div className="position-relative top-50">
              <p className="my-5 text-center text-black">Summary Not Found</p>
            </div>
          ) : (
            <>
              <div className="row border m-3 rounded-2 p-1" style={{ boxShadow: "inset 0 0 10px #cbcacabd" }}>
                <a className="col-6 text-center p-2 fw-bold text-white rounded-2 fs-6" style={{ background: "#009EC1" }}>
                  Summary
                </a>
                <a onClick={() => setShowChats(true)} className="col-6 text-center p-2 rounded-2 text-black fs-6 fw-medium">
                  Chats
                </a>
              </div>
              <div>
                {!!analysis?.whatsapp_disposition && (
                  <div className="m-3 mt-4 rounded-5 bg-body-tertiary px-2 py-3">
                    {Object.entries(parseJson(analysis?.whatsapp_disposition))?.map(([key, value], i) => (
                      <Fragment key={i}>
                        {!!value && (
                          <p className="text-black px-3 mb-3">
                            <strong className="fs-5 fw-bold my-2 d-inline-block">{snakeToNormal(key)}</strong>
                            <br />
                            <span className="text-black fs-6">{value}</span>
                          </p>
                        )}
                      </Fragment>
                    ))}
                  </div>
                )}
                {!analysis.whatsapp_disposition && <p className="my-5 text-center text-black">Summary Not Found</p>}
              </div>
              <WhatsappMsg
                show={showChats}
                setShow={setShowChats}
                name={data?.data?.customer_name}
                mobileNo={data?.data?.customer_mobile_number}
                isCandidate={false}
                withSendMessage={false}
                isMasked={!userData?.remove_masking}
              />
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
