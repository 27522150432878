import CheckIcon from "../../Static/RawImages/check.png";
import InfoIcon from "../../Static/RawImages/info-icon.png";
import { findCommunicationHistory } from "../../utils/findCommunicationHistory";
import ReactTooltip from "react-tooltip";

// variants => green, grey, blue, red, info
// icon => email, whatsapp, sms, ivr, agent

export const IconVariant = ({ icon = "email", variant = "", index = "", labelSize = "0.6rem" }) => {
    const label = icon === "email" ? "Email" : icon === "sms" ? "SMS" : icon === "agent" ? "Disp." : icon === "ivr" ? "Call" : "Whatsapp";
    const tooltip = findCommunicationHistory({ icon, variant });
    return (
        <>
            <span className="d-flex align-items-center justify-content-start gap-1 flex-column" data-tip data-for={`icon_variant_${index}_${variant}_${icon}`}>
                <span style={{ height: "1.5rem" }} className="item-icon position-relative m-0">
                    {variant === "info" && <img src={InfoIcon} />}

                    {variant !== "info" && (
                        <>
                            {icon === "email" && (
                                <svg width="24" height="18" xmlns="http://www.w3.org/2000/svg">
                                    <g
                                        fill={
                                            variant === "blue" || variant === "green"
                                                ? "#0A7AFF"
                                                : variant === "red"
                                                ? "#ff0000"
                                                : variant === "grey"
                                                ? "#999"
                                                : "#ddd"
                                        }
                                        fillRule="nonzero"
                                    >
                                        <path d="M0 0v12.96a4.32 4.32 0 0 0 4.32 4.32h14.4a4.32 4.32 0 0 0 4.32-4.32V0H0zm22.08 12.96a3.36 3.36 0 0 1-3.36 3.36H4.32a3.36 3.36 0 0 1-3.36-3.36v-12h21.12v12z" />
                                        <path d="m20.39 3.682-.643-.71-8.227 7.42-8.18-7.411-.642.71 8.822 7.997z" />
                                    </g>
                                </svg>
                            )}
                            {icon === "whatsapp" && (
                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <g
                                        fill={
                                            variant === "blue" || variant === "green"
                                                ? "#0A7AFF"
                                                : variant === "red"
                                                ? "#ff0000"
                                                : variant === "grey"
                                                ? "#999"
                                                : "#ddd"
                                        }
                                        fillRule="nonzero"
                                    >
                                        <path d="M11.52 0A11.52 11.52 0 0 0 1.44 17.074L0 23.04l5.822-1.507A11.52 11.52 0 1 0 11.52 0zm0 22.08a10.56 10.56 0 0 1-5.38-1.474l-.174-.105-4.608 1.18 1.1-4.742-.101-.173a10.56 10.56 0 1 1 9.163 5.314z" />
                                        <path d="M7.632 13.239a14.745 14.745 0 0 0 4.905 3.842c.717.34 1.676.743 2.744.812.067.003.13.006.196.006.717 0 1.293-.248 1.763-.758.003-.002.008-.008.011-.014.167-.202.357-.383.556-.576.136-.13.274-.265.406-.403.614-.64.614-1.452-.005-2.07l-1.731-1.732c-.294-.305-.645-.466-1.014-.466s-.723.161-1.025.463l-1.031 1.031c-.095-.054-.193-.103-.286-.15a3.561 3.561 0 0 1-.316-.172c-.94-.596-1.792-1.374-2.607-2.373-.412-.522-.688-.96-.881-1.406.27-.245.524-.5.769-.751.086-.09.176-.179.265-.268.31-.311.478-.671.478-1.037 0-.366-.164-.726-.478-1.037l-.858-.858c-.101-.1-.196-.199-.294-.3-.19-.195-.389-.397-.585-.578C8.318 4.153 7.97 4 7.6 4c-.365 0-.717.153-1.025.446L5.498 5.524a2.213 2.213 0 0 0-.66 1.416c-.054.689.073 1.42.4 2.304.505 1.368 1.265 2.639 2.394 3.995zm-2.09-6.238c.034-.383.18-.703.457-.98l1.072-1.07c.167-.162.35-.245.53-.245.175 0 .354.083.518.25.193.179.374.366.57.565l.3.305.858.858c.178.179.27.36.27.539 0 .178-.092.36-.27.538l-.268.271c-.268.27-.518.527-.795.772l-.014.014c-.24.24-.202.467-.144.64.003.008.006.014.008.023.222.532.53 1.04 1.011 1.644.864 1.066 1.774 1.892 2.777 2.529.123.08.256.144.38.207.115.058.222.112.317.173l.031.017a.602.602 0 0 0 .28.072.61.61 0 0 0 .429-.196l1.077-1.077c.167-.167.348-.256.527-.256.219 0 .397.135.51.256l1.736 1.734c.346.346.343.72-.008 1.086-.121.13-.248.253-.383.383-.202.196-.412.397-.602.625-.332.357-.726.524-1.236.524-.049 0-.1-.003-.15-.006-.944-.06-1.823-.429-2.482-.743a14.008 14.008 0 0 1-4.669-3.657c-1.074-1.294-1.797-2.497-2.275-3.788-.296-.792-.409-1.428-.363-2.007z" />
                                    </g>
                                </svg>
                            )}
                            {icon === "sms" && (
                                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.52 0A11.52 11.52 0 0 0 1.44 17.074L0 23.04l5.822-1.507A11.52 11.52 0 1 0 11.52 0zm0 22.08a10.56 10.56 0 0 1-5.38-1.474l-.174-.105-4.608 1.18 1.1-4.742-.101-.173a10.56 10.56 0 1 1 9.163 5.314z"
                                        fill={
                                            variant === "blue" || variant === "green"
                                                ? "#0A7AFF"
                                                : variant === "red"
                                                ? "#ff0000"
                                                : variant === "grey"
                                                ? "#999"
                                                : "#ddd"
                                        }
                                        fillRule="nonzero"
                                    />
                                </svg>
                            )}
                            {icon === "ivr" && (
                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M18.01 14.549a2.98 2.98 0 0 0-1.844.216l-2.068.893a20.342 20.342 0 0 1-3.668-2.91 19.872 19.872 0 0 1-2.88-3.72l.677-1.862c.24-.565.302-1.189.178-1.79L7.027 0 0 1.013l.029.427a23.18 23.18 0 0 0 6.657 14.88 22.853 22.853 0 0 0 15.15 6.72h.455l.749-7.315-5.03-1.176zm3.422 7.488a21.864 21.864 0 0 1-14.064-6.36A22.224 22.224 0 0 1 1.013 1.834l5.28-.764 1.142 4.517c.082.416.035.846-.134 1.234L6.48 9.12l.12.197a21.12 21.12 0 0 0 3.149 4.123 21.706 21.706 0 0 0 4.046 3.178l.216.139 2.554-1.1a1.992 1.992 0 0 1 1.243-.153l4.2.96-.576 5.573z"
                                        fill={
                                            variant === "blue" || variant === "green"
                                                ? "#0A7AFF"
                                                : variant === "red"
                                                ? "#ff0000"
                                                : variant === "grey"
                                                ? "#999"
                                                : "#ddd"
                                        }
                                        fillRule="nonzero"
                                    />
                                </svg>
                            )}
                            {icon === "agent" && (
                                <svg width="19" height="25" viewBox="0 0 19 25" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="m16.817 19.622-3.526-1.961a1.079 1.079 0 0 1-.593-.97l-.002-.982a9.482 9.482 0 0 0 1.63-3.33c.007-.03.01-.06.01-.091v-.82h1.229a1.23 1.23 0 0 0 1.229-1.228V7.782a1.227 1.227 0 0 0-.82-1.153v-.075a6.554 6.554 0 0 0-13.107 0v.075c-.49.172-.817.634-.819 1.153v2.458c.002.519.33.98.82 1.153v.895a1.64 1.64 0 0 0 1.638 1.638h.563c.298.628.659 1.224 1.075 1.78v.944c.002.397-.216.763-.565.951l-3.653 1.995A3.686 3.686 0 0 0 0 22.839v1.327a.41.41 0 0 0 .41.41h18.022a.41.41 0 0 0 .41-.41v-1.245a3.673 3.673 0 0 0-2.025-3.299zm-.843-11.84v2.458a.41.41 0 0 1-.41.41h-1.228V7.373h1.229a.41.41 0 0 1 .41.41zM3.277 10.65a.41.41 0 0 1-.41-.41V7.782a.41.41 0 0 1 .41-.41h1.229v3.278h-1.23zm1.229 2.457a.82.82 0 0 1-.82-.819v-.82h.82v.82c0 .03.003.062.01.092.059.246.13.489.217.727h-.227zm-.002-6.553h-.818A5.74 5.74 0 0 1 9.421.819a5.74 5.74 0 0 1 5.734 5.735h-.817a3.664 3.664 0 0 0-.858-2.65c-.841-.96-2.207-1.446-4.06-1.446-1.851 0-3.217.486-4.058 1.446a3.664 3.664 0 0 0-.858 2.65zM.819 23.757v-.918a2.868 2.868 0 0 1 1.5-2.524l3.65-1.993c.614-.332.996-.974.994-1.672v-.944a.808.808 0 0 0-.166-.494 9.738 9.738 0 0 1-.813-1.286h1.874c.172.49.634.818 1.153.82h1.23a1.229 1.229 0 0 0 0-2.458H9.01c-.519.002-.98.33-1.153.82H5.606a6.369 6.369 0 0 1-.281-.867V6.554a2.842 2.842 0 0 1 .653-2.11c.679-.775 1.837-1.167 3.443-1.167 1.6 0 2.756.39 3.435 1.158a2.88 2.88 0 0 1 .66 2.119v5.687a8.666 8.666 0 0 1-1.472 2.97.808.808 0 0 0-.166.495v.985a1.886 1.886 0 0 0 1.03 1.693l3.526 1.962a2.866 2.866 0 0 1 1.588 2.575v.836H.82zm7.783-10.24a.41.41 0 0 1 .41-.41h1.228a.41.41 0 0 1 0 .82H9.011a.41.41 0 0 1-.41-.41z"
                                        fill={
                                            variant === "blue" || variant === "green"
                                                ? "#0A7AFF"
                                                : variant === "red"
                                                ? "#ff0000"
                                                : variant === "grey"
                                                ? "#999"
                                                : "#ddd"
                                        }
                                        fillRule="nonzero"
                                    />
                                </svg>
                            )}
                        </>
                    )}

                    {variant === "green" && (
                        <div
                            style={{
                                backgroundColor: "#12c312",
                                borderRadius: "50%",
                                height: "13px",
                                width: "13px",
                                position: "absolute",
                                bottom: "-4px",
                                right: "-4px",
                                padding: "3px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <img src={CheckIcon} style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                        </div>
                    )}
                </span>
                <span style={{ fontSize: labelSize }} className="font-semibold text-center cl-gray-02 text-12">
                    {label}
                </span>
                <ReactTooltip
        id={`icon_variant_${index}_${variant}_${icon}`}
        effect="solid"
        place="top"
        className="py-2 px-3 text-sm"
        offset={{ bottom: 5 }}
      >
        <div className="font-normal">{tooltip}</div>
      </ReactTooltip>
            </span>
        </>
    );
};
    