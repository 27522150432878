
import React, { useState } from 'react';
import { Container, Row, Col, Button, Form, Card, Accordion } from 'react-bootstrap';
import { FaPlay, FaCopy, FaLock } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';

const ApiEndpoint = ({ method, endpoint, description }) => (
  <div className="d-flex align-items-center mb-2">
    <span className={`px-2 py-1 me-2 rounded text-white ${
      method === 'GET' ? 'bg-success' :
      method === 'POST' ? 'bg-primary' :
      method === 'PUT' ? 'bg-warning' :
      method === 'DELETE' ? 'bg-danger' :
      'bg-secondary'
    }`}>
      {method}
    </span>
    <code className="flex-grow-1">{endpoint}</code>
    <FaLock className="ms-2" />
  </div>
);

const APIIntegration = () => {
  const [accessToken, setAccessToken] = useState('Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJjYzNhOTYwMC1');
  const [apiEndpoint, setApiEndpoint] = useState('https://api.themedius.ai/');

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const apiEndpoints = [
    { method: 'GET', endpoint: '/dashboard/api/getMessages/{whatsappNumber}', description: 'Get Message by WhatsApp Number' },
    { method: 'POST', endpoint: '/dashboard/api/getMessageTemplates', description: 'Get Message Templates' },
    { method: 'PUT', endpoint: ' /dashboard/api/getContacts', description: 'Get Contacts List' },
    { method: 'DELETE', endpoint: '/dashboard/api/rotateTeken', description: 'Rotate Token' },
    { method: 'GET', endpoint: '/dashboard/api/addContact', description: 'Add Contact' },
    { method: 'POST', endpoint: '/dashboard/api/sendTemplateMessage', description: 'Send Template Message'},
  ];

  return (
    <Container className="my-5">
      <Row className="mb-4 align-items-center">
        <Col>
          <h1>META APIs for WhatsApp</h1>
        </Col>
        <Col xs="auto">
          <Button variant="outline-success" className="me-2">Authorize</Button>
          <Button variant="primary">Download Postman Collection</Button>
        </Col>
      </Row>

      <p className="mb-4">
        Our developer-friendly RESTful APIs are super-easy to integrate and have bearer authentication mechanism. You can integrate these APIs with your own programming languages.
      </p>

      <div className="mb-4">
        <Button variant="success" className="me-2">API Docs</Button>
        <Button variant="primary">
          <FaPlay className="me-2" />
          Watch Tutorial
        </Button>
      </div>

      <Card className="mb-4">
        <Card.Body>
          <Row className="mb-3 align-items-center">
            <Col xs={12} md={3} className="fw-bold">Access Token</Col>
            <Col xs={12} md={7}>
              <Form.Control
                type="text"
                value={accessToken}
                onChange={(e) => setAccessToken(e.target.value)}
                readOnly
              />
            </Col>
            <Col xs={12} md={2} className="mt-2 mt-md-0">
              <Button variant="outline-success" onClick={() => copyToClipboard(accessToken)}>
                <FaCopy className="me-2" />
                Copy
              </Button>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={12} md={3} className="fw-bold">API Endpoint</Col>
            <Col xs={12} md={7}>
              <Form.Control
                type="text"
                value={apiEndpoint}
                onChange={(e) => setApiEndpoint(e.target.value)}
                readOnly
              />
            </Col>
            <Col xs={12} md={2} className="mt-2 mt-md-0">
              <Button variant="outline-success" onClick={() => copyToClipboard(apiEndpoint)}>
                <FaCopy className="me-2" />
                Copy
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>API</Accordion.Header>
          <Accordion.Body>
            {apiEndpoints.map((api, index) => (
              <React.Fragment key={index}>
                <ApiEndpoint {...api} />
                <p className="text-muted mb-3">{api.description}</p>
              </React.Fragment>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export default APIIntegration;