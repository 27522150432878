import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AgentContextProvider } from "../contexts/AgentContext";
import AgentHeader from "./Components/AgentHeader";
import AgentSidebar from "./Components/AgentSidebar";
import AgentLogin from "./Pages/AgentLogin";
import useGeneralStore from "../store/generalStore";
import NewImpLeads from "./Pages/New/ImpLeads";
import AdditionalPage from "./Pages/New/Additional";
import BrokenPTP from "./Pages/Followups/BrokenPTP";
import PendingDisp from "./Pages/Followups/PendingDisp";
import PTPLeadsPage from "./Pages/Followups/PTPLeads";
import SettlementLeadPage from "./Pages/Followups/Settlement";
import MySettlementLeadPage from "./Pages/Followups/MySettlement";
import SettlementDispositionPage from "./Pages/Followups/SettlementDisposition"
import MyPermonance from "./Pages/MyPermonance";
import ProfileForm from "./Pages/Profile/ProfileForm";
import useAuthStore from "../store/authStore";
import ProtectedRoute from "./ProtectedRoute";
import BorrowerInfo from "./features/borrower-info";
import AcceptedAmount from "./Pages/Followups/AcceptedAmount";
import SettlementLetterIssued from "./Pages/Followups/SettlementLetterIssued";
// import UpdatePassword from "./Pages/update-password";

export default function AgentRoutes() {
    const isSidebarOpen = useGeneralStore((state) => state.is_sidebar_open);
    const profileCompleted = useAuthStore((state) => state.profileCompleted);

    return (
        <AgentContextProvider>
            <Route exact path="/login" component={AgentLogin} />
            {!profileCompleted && (
                <>
                    <ProtectedRoute exact path="/agent/profile/update" component={ProfileForm} />

                    {/* redirect if none of the above route exists */}
                    <Route exact path="*">
                        <Redirect to="/agent/profile/update" />
                    </Route>
                </>
            )}

            {!!profileCompleted && (
                <>
                    <aside className={`${isSidebarOpen ? "sidebar" : "sidebar sidebar-hide"}`}>
                        <AgentSidebar />{" "}
                    </aside>
                    <section className={`${isSidebarOpen ? "content" : "content content-full"}`}>
                        <AgentHeader />
                        <div className="content-part">
                            {/* NEW Imp Leads */}
                            <ProtectedRoute exact path="/agent/calls/new/imp-leads" component={NewImpLeads} />

                            {/* Additional */}
                            <ProtectedRoute exact path="/agent/calls/new/additional" component={AdditionalPage} />

                            {/* Broken PTP */}
                            <ProtectedRoute exact path="/agent/calls/new/broken-ptp" component={BrokenPTP} />

                            {/* Pending DISP */}
                            <ProtectedRoute exact path="/agent/calls/new/pending-disp" component={PendingDisp} />

                            {/* PTP Leads */}
                            <ProtectedRoute exact path="/agent/calls/new/ptp-leads" component={PTPLeadsPage} />

                            {/*My Settle Leads Page*/}
                            <ProtectedRoute exact path="/agent/calls/my-settlement-leads" component={MySettlementLeadPage} />

                            {/*Settle Disposition Leads Page*/}
                            <ProtectedRoute exact path="/agent/calls/settlement-disposition" component={SettlementDispositionPage} />

                            {/*Settlement Letter Issued*/}
                            <ProtectedRoute exact path="/agent/calls/settlement-letter-issued" component={SettlementLetterIssued} />

                            {/*Settlement Accepted Amount*/}
                            <ProtectedRoute exact path="/agent/calls/settlement-accepted-amount" component={AcceptedAmount} />

                            {/* My Perfomance */}
                            <ProtectedRoute exact path="/agent/my-perfomance" component={MyPermonance} />

                            {/* redirect if none of the above route exists */}
                            <Route exact path="*">
                                <Redirect to="/agent/calls/new/imp-leads" />
                            </Route>

                            {/* Other contents */}
                            <BorrowerInfo />
                        </div>
                    </section>
                </>
            )}
        </AgentContextProvider>
    );
}
