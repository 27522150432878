import { useEffect, useMemo, useRef } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { WEB_SOCKET_URL } from "../apis/endpoints";
import useAuthStore from "../store/authStore";

export default function useCustomSocket({ typeFilters = [], onMessage = () => {} }) {
    const token = useAuthStore((state) => encodeURIComponent(state.token));

    const didUnmount = useRef(false);

    const { readyState } = useWebSocket(WEB_SOCKET_URL, {
        queryParams: { token },
        retryOnError: true,
        shouldReconnect: () => didUnmount.current === false,
        reconnectAttempts: 50,
        reconnectInterval: 10000,
        onMessage: (msg) => {
            const json = JSON.parse(msg?.data);
            if (!typeFilters.includes(json?.type)) return;
            onMessage(json);
        },
    });

    const status = useMemo(
        () =>
            ({
                [ReadyState.CONNECTING]: "Connecting",
                [ReadyState.OPEN]: "Connected",
                [ReadyState.CLOSING]: "Closing",
                [ReadyState.CLOSED]: "Closed",
                [ReadyState.UNINSTANTIATED]: "Uninstantiated",
            }[readyState]),
        [readyState]
    );

    useEffect(() => {
        return () => {
            didUnmount.current = true;
        };
    }, []);

    return { status };
}
