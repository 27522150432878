import React, { useRef, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { login, sendAgentOTP } from "../../API/commonApis";

import agentbg from "../../Static/RawImages/agent-bg.png";
import mediusWhite from "../../Static/RawImages/logo-white.png";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import useAuthStore from "../../store/authStore";
import { Button, Modal } from "react-bootstrap";
import { IoCloseCircle } from "react-icons/io5";

function AgentLogin() {
  const createUser = useAuthStore((state) => state.createUser);
  const removeUser = useAuthStore((state) => state.removeUser);
  const isUserAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const updateProfileCompleted = useAuthStore((state) => state.updateProfileCompleted);
  const profileCompleted = useAuthStore((state) => state.profileCompleted);

  const [authError, setAuthError] = useState("");
  const [otpInfo, setOTPInfo] = useState({
    type: null,
    message: null,
  });
  const [loading, setLoading] = useState(false);
  const [otpLoading, setOTPLoading] = useState(false);

  const [showOTPModal, setShowOTPModal] = useState(false);

  const email = useRef(null);
  const password = useRef(null);
  const otp = useRef(null);

  const history = useHistory();

  const sendOTP = async (body = {}) => {
    const res = await sendAgentOTP({ ...body, email: email.current.value });

    const data = res || {
      status: false,
      message: "Can't send OTP! Please Try Again",
    };

    setOTPInfo({
      type: data.status ? "info" : "danger",
      message: data.message,
    });

    return data;
  };

  const handleOTP = async (e) => {
    e.preventDefault();

    if (!email.current.value) {
      setAuthError("Please Enter Email");
      return;
    }

    if (!password.current.value) {
      setAuthError("Please Enter Password");
      return;
    }

    setLoading(true);

    const res = await sendOTP();

    if (!res.status) {
      setAuthError(res.message);
      setLoading(false);
      return;
    }

    setShowOTPModal(true);
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
  
    if (!email.current.value) {
      setAuthError("Please Enter Email");
      return;
    }
  
    if (!password.current.value) {
      setAuthError("Please Enter Password");
      return;
    }
  
    if (!otp.current.value) {
      setOTPInfo({
        type: "danger",
        message: "Please Enter OTP",
      });
      return;
    }
    
    setOTPLoading(true);
    setLoading(true);
  
    try {
      localStorage.clear();
      const formData = new FormData();
      formData.append("email", email.current.value?.toLowerCase()?.trim());
      formData.append("password", password.current.value);
      formData.append("otp", otp.current.value);
  
      const res = await login(formData);
  
      setLoading(false);
      setOTPLoading(false);
  
      let data = res.data;
      let message = res.message;
  
      if (res === "error") {
        setAuthError("Invalid Credentials.");
      }
  
      if (!data) {
        if (message?.match(/otp/i)) {
          // Keep the modal open if there's an OTP error
          setOTPInfo({
            type: "danger",
            message,
          });
        } else {
          setShowOTPModal(false); // Close modal for other types of errors
        }
  
        setAuthError(message ?? "");
        return;
      }
  
      if (!["super_agent", "agent", "chat_agent", "agent"].includes(data?.role)) {
        setAuthError("Not A Agent's Email");
        return;
      }
  
      if (!!data?.is_deactivated) {
        setAuthError("Your account has been deactivated. Please contact the administrator for more information.");
        return;
      }
  
      if (!data?.email_verified) {
        setAuthError("Your email is not verified.");
        return;
      }
  
      removeUser();
      setAuthError("");
  
      const userData = {
        email: data?.email,
        full_name: data?.full_name,
        user_id: data?.user_id,
        client_id: data?.active_client?.client_id,
        logo_url: data?.active_client?.logo_url,
        mobile: data?.mobile,
        clients: data?.agent_clients,
        role: data?.role,
        settings: { ...data?.settings },
      };
  
      if (data?.masking_allowed === false) userData["remove_masking"] = true;
  
      createUser({
        token: data?.token,
        role: "agent",
        userData,
      });
      updateProfileCompleted(!!data?.profile_completed);
  
      // Close the modal only on successful login
      setShowOTPModal(false);
    } catch (error) {
      setAuthError("Unknown Error Occurred");
      console.error(error);
      setLoading(false);
      setOTPLoading(false);
    }
  };
  

  if (isUserAuthenticated) {
    if (profileCompleted) history.push("/agent/calls/new/imp-leads");
    else history.push("/agent/profile/update");
    return <></>;
  }

  return (
    <div style={{ backgroundImage: `url(${agentbg})` }} className="agent-login">
      <div className="container-fluid">
        <ToastContainer
          theme="dark"
          position="top-right"
          autoClose={5000}
          pauseOnFocusLoss={false}
          pauseOnHover={false}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
        />
        <div className="row">
          <div className="col-md-12 ps-5">
            <img src={mediusWhite} alt="login" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="d-flex justify-content-end">
          <div style={{ maxWidth: "25rem" }}>
            <form onSubmit={handleOTP}>
              <div className="login-text">
                <span>Welcome To</span>
                <br />
                Agent Panel
              </div>

              <div style={{ marginTop: "-0.5rem", fontSize: "0.9rem", color: "red" }} className="mb-3">
                {authError}
              </div>

              <div className="mb-2">
                <label htmlFor="login_email" className="col-form-label mb-0">
                  Email Id
                </label>
                <div>
                  <input ref={email} type="email" id="login_email" className="form-control" required />
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="login_password" className="col-form-label mb-0">
                  Password
                </label>
                <div>
                  <input ref={password} type="password" id="login_password" className="form-control" />
                </div>
              </div>
              <div className="mb-3">
                <button disabled={loading} type="submit" className="btn btn-primary py-3 w-100">
                  Get OTP
                </button>
              </div>

              <div className="text-center text-white">
                Forgot Password? {/* FIXME: */}
                <Link to="/agent/update-password" style={{ color: "#009ec0", fontWeight: "800" }} href="/agent/update-password">
                  Click Here
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal size="md" show={showOTPModal} contentClassName="overflow-hidden text-white">
        <Modal.Header className="d-flex justify-content-between bg-dark">
          <Modal.Title className="text-white">Verify OTP</Modal.Title>
          <button
            onClick={() => {
              setShowOTPModal(false);
              setLoading(false);
            }}
            className="border-0 bg-transparent"
          >
            <IoCloseCircle size={24} color="white" />
          </button>
        </Modal.Header>
        <Modal.Body className="bg-dark">
          {otpInfo.type === "info" ? <p className="pt-2 text-info m-0">{otpInfo.message}</p> : <p className="pt-2 text-danger m-0">{otpInfo.message}</p>}

          <form onSubmit={handelSubmit}>
            <div className="d-flex p-4 gap-4 align-items-center">
              <label htmlFor="login_otp" className="text-white fw-medium">
                Enter OTP:
              </label>
              <input
                ref={otp}
                type="text"
                id="login_otp"
                maxLength={6}
                minLength={6}
                className="form-control"
                inputMode="numeric"
                pattern="\d{6}"
                title="OTP should be 6 digit numeric key"
                required
                style={{ maxWidth: "15ch", letterSpacing: "1ch", fontSize: "1.1rem", fontWeight: "bold" }}
                autoComplete="off"
              />
            </div>
            <Modal.Footer className="d-flex justify-content-start">
              <Button variant="primary" type="submit" disabled={otpLoading}>
                Submit OTP and Login
              </Button>
              <Button
                variant="link"
                className="text-decoration-none text-info"
                onClick={(e) => {
                  e.target.disabled = true;
                  sendOTP({ type: "resend" });
                }}
              >
                Resend OTP
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AgentLogin;
