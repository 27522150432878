import OnlineCaseStatus from "./online-case-status";
import CallResponse from "./call-response";
import WhatsappResponse from "./whatsapp-response";
import EmailResponse from "./email-response";
import Conciliation from "./conciliation";
import PaymentStatus from "./payment-status";

import SettlementStatus from "./settlement-status";
import SettlementConfirmation from "./settlement-confirmation";
import DispositionHistory from "../../../../Components/DispositionHistory";
import { useEffect, useState } from "react";
import useAgentStore from "../../../../../store/agent/agentStore";
import useGeneralStore from "../../../../../store/generalStore";
import { fetchAccountData } from "../../../../../API/agentApis";
import useAuthStore from "../../../../../store/authStore";
import AnalysisCard from "./analysis-card";
import { MdOutlineSupportAgent } from "react-icons/md";
import useBorrowerInfo from "../../store";

const ALERT_NO_CUSTOMER_ID = "No customer ID found";
const ALERT_FETCH_FAILED = "Unable to fetch customer details";

export default function Analysis({ info }) {
  const [showDispHistory, setShowDispHistory] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const showAlert = useGeneralStore((state) => state.open_alert);
  const removeCustomerSourceOfLeadData = useAgentStore((state) => state.removeCustomerSourceOfLeadData);
  const removeCustomerId = useAgentStore((state) => state.removeCustomerId);
  
  const customerId = useBorrowerInfo((state) => state.customerId);

  console.log(customerId,"customerId")

  const fetchData = async () => {
    if (!customerId) {
      showAlert({ variant: "danger", msg: ALERT_NO_CUSTOMER_ID });
      return;
    }

    setLoading(true);
    try {
      const res = await fetchAccountData({ customer_id: customerId });
      if (res) {
        setData(res);
        console.log(res,"customerMobileNo")

      }
    } catch (error) {
      showAlert({ variant: "danger", msg: ALERT_FETCH_FAILED });
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    if (customerId) {
      
      fetchData();
    }
  }, [customerId]);

  useEffect(() => {
    return () => {
      removeCustomerSourceOfLeadData();
      removeCustomerId();
    };
  }, []);

  return (
    <>
      <DispositionHistory
        show={showDispHistory}
        setShow={setShowDispHistory}
        customerMobileNo={data?.data?.customer_mobile_number}
      />
      <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }} className="col-12 gap-3">
        <CallResponse />
        <OnlineCaseStatus />
        <WhatsappResponse />
        <EmailResponse />
        <Conciliation info={info} />
        <SettlementStatus />
        
        {/* Display icon and action */}
        {/* <div className="d-block" onClick={() => setShowDispHistory(true)}>
          <IconVariant icon="agent" variant={data?.communication_history?.agent_data} labelSize="0.8rem" />
        </div> */}
        <AnalysisCard onClick={() => setShowDispHistory(true)} title="Disposition History" isLoading={false} tag="" content="" icon={MdOutlineSupportAgent} isDisposition={true} DispostionTag={info?.data.actual_disposition} />


        <SettlementConfirmation />
      </div>
    </>
  );
}
