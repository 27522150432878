import React, { Suspense, useMemo } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import AdminHeader from "./Components/AdminHeader";
import Login from "./Pages/login";
import SearchAny from "./Components/Search";
import Sidebar from "./Components/new-sidebar";
import routes from "./routes";
import ProtectedRoute from "./ProtectedRoute";
import useAuthStore from "../store/authStore";

const hideHeaderSidebarRoutes = ["/admin/daily-ops/search-firs"];

export default function AdminApp() {
  const location = useLocation();
  const userdata = useAuthStore((state) => state.userData);
  const hideUserid = [
    1009, 1010, 1011, 1012, 1013, 1014,
    1020, 1021, 1022, 1023, 1024, 1025,
    1026, 1027, 1028, 1029, 1030,
    1034, 1035, 1036, 1037, 1038, 1039,
    1040, 1041, 1042, 1043, 1044, 1045,
    1046, 1047, 1048
  ];
  
    const chathideuser =[1017,1018]
  const isHideHeaderAndSidebar = useMemo(() => hideHeaderSidebarRoutes.some((el) => location.pathname?.indexOf(el) > -1), [location.pathname]);

  return (
    <>
      <Route exact path="/login" component={Login} />

      <div className="d-flex w-100">
        {!isHideHeaderAndSidebar && <Sidebar />}

        <div style={{ overflow: "auto", flexGrow: "1" }}>
          {!isHideHeaderAndSidebar && <AdminHeader />}

          <SearchAny />

          <div className="position-relative p-0 h-100">
            <Route exact path="/">
            {
  hideUserid.includes(userdata?.user_id) ? (
    <Redirect to="/admin/daily-ops/call-quality" exact />
  ) : chathideuser.includes(userdata?.user_id) ? (
    <Redirect to="/admin/chat-panel/chat" exact />
  ) : (
    <Redirect to="/admin/others/dashboard" exact />
  )
}

            </Route>
            <Suspense fallback={<div>Loading...</div>}>
              {routes.map((route) => (
                <ProtectedRoute key={route.path} exact {...route} />
              ))}
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
}
