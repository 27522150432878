
import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Table, Dropdown, Badge } from 'react-bootstrap';
import { FaSearch, FaFileExport, FaFileImport, FaPlay, FaEllipsisV, FaCopy, FaTrash } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';

const Templates = () => {
  const [sortBy, setSortBy] = useState('Latest');
  const [searchTerm, setSearchTerm] = useState('');

  const templates = [
    { id: 1, name: 'overdue_notice', category: 'Utility', status: 'Approved', language: 'Albanian', lastUpdated: '10/11/2024' },
    { id: 2, name: 'pre_fir_reminder_2024_1', category: 'Utility', status: 'Approved', language: 'English (US)', lastUpdated: '10/11/2024' },
    { id: 3, name: 'police_statement_to_inquiry_off...', category: 'Utility', status: 'Approved', language: 'English (US)', lastUpdated: '7/24/2024' },
    { id: 4, name: 'statement_copy_to_police_2024', category: 'Utility', status: 'Approved', language: 'English (US)', lastUpdated: '2/23/2024' },
  ];

  const filteredTemplates = templates.filter(template =>
    template.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container fluid className="py-4">
      <Row className="mb-4 align-items-center">
        <Col xs={12} md={4} lg={3} className="mb-2 mb-md-0">
          <Form.Group className="d-flex align-items-center">
            <Form.Label className="me-2 mb-0">Sorted by:</Form.Label>
            <Form.Select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
              <option>Latest</option>
              <option>Oldest</option>
              <option>Name</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs={12} md={4} lg={3} className="mb-2 mb-md-0">
          <Form.Group className="position-relative">
            <Form.Control
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <FaSearch className="position-absolute top-50 end-0 translate-middle-y me-2 text-muted" />
          </Form.Group>
        </Col>
        <Col xs={12} md={4} lg={6} className="d-flex justify-content-md-end align-items-center">
          <Button variant="primary" className="me-2 d-flex align-items-center">
            <span className="me-2">0</span>
            <FaEllipsisV />
          </Button>
          <Button variant="outline-secondary" className="me-2">
            <FaFileExport className="me-2" />
            Export
          </Button>
          <Button variant="outline-secondary" className="me-2">
            <FaFileImport className="me-2" />
            Import
          </Button>
          <Button variant="primary" className="me-2">
            <FaPlay className="me-2" />
            Watch Tutorial
          </Button>
          <Button variant="primary">New Template Message</Button>
        </Col>
      </Row>

      <div className="alert alert-info" role="alert">
        All templates must adhere to WhatsApp's Template Message Guidelines. <a href="#" className="alert-link">Click here to read</a>
      </div>

      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>Template Name</th>
            <th>Category</th>
            <th>Status</th>
            <th>Language</th>
            <th>Last Updated</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredTemplates.map((template) => (
            <tr key={template.id}>
              <td>
                <a href="#" className="text-primary">{template.name}</a>
              </td>
              <td>{template.category}</td>
              <td>
                <Badge bg="primary" pill>{template.status}</Badge>
              </td>
              <td>{template.language}</td>
              <td>{template.lastUpdated}</td>
              <td>
                <Button variant="outline-primary" size="sm" className="me-1">
                  Send broadcast
                </Button>
                <Button variant="outline-secondary" size="sm" className="me-1">
                  <FaCopy />
                </Button>
                <Button variant="outline-danger" size="sm">
                  <FaTrash />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Templates;