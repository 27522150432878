export const convertToMapValue = (normalText) =>
    String(normalText)
        ?.trim()
        ?.toLowerCase()
        ?.replace(/[^0-9a-z]/gi, "");

export const labelMapping = {
    loan_account_no: "Loan Account No",
    customer_name: "Customer Name",
    customer_state: "State",
    customer_pincode: "Pincode",
    product_group: "Product Group",
    zone_name: "Zone Name",
    region_name: "Region Name",
    branch_code: "Branch Code",
    first_name: "First Name",
    branch_name: "Branch Name",
    due_agmt_no: "Agreement Number",
    tenor: "Tenure",
    adv_emi: "Advance EMI",
    mob: "MOB",
    bkt: "Due Since / BKT",
    emi: "EMI",
    demand: "Demand",
    overdue: "Overdue",
    total_charges: "Total Charges",
    total_od: "Total OD",
    future_princ: "Future Princ",
    pos: "Total Outstanding",
    customer_address1: "Customer Address",
    customer_mobile_number: "Primary Mobile Number",
    customer_alt_contact_no: "Alternate Mobile Number",
    ref_num_1: "Reference Number #1",
    ref_name_1: "Reference Name #1",
    ref_num_2: "Reference Number #2",
    ref_name_2: "Reference Name #2",
    ref_num_3: "Reference Number #3",
    ref_name_3: "Reference Name #3",
    ref_num_4: "Reference Number #4",
    ref_name_4: "Reference Name #4",
    first_emi_date: "First EMI Date",
    last_emi_date: "Last EMI Date",
    amount_finanaced: "Amount Financed",
    disbursal_date: "Disbursal Date",
    status: "Status",
    payment_type: "Payment Type",
    customer_city: "Customer City",
    customer_landmark: "Customer Landmark",
    office_address1: "Office Address Line 1",
    office_address2: "Office Address Line 2",
    office_address3: "Office Address Line 3",
    office_pincode: "Office Address Pincode",
    office_mobile_number: "Office Contact Number",
    model: "Model",
    reg_no: "Registration Number",
    chassis_no: "Chasis No",
    eng_no: "Eng No",
    last_collection_date: "Last Collection Date",
    ltv: "LTV",
    loan_amount: "Loan Amount",
    customer_profile: "Customer Profile",
    morat_type: "Morat Type",
    due_date: "Due Date",
    father_name: "Father Name",
    due_prop_no: "Due Prop No",
    product_name: "Product Name",
    address2: "Address 2",
    agreement_date: "Agreement Date",
    arbitration_status: "Arbitration Status",
    home_address_pincode: "Home Address Pincode",
    lrn_status: "LRN Status",
    receivable: "Receivable",
    agening: "Agening",
    asset_cost: "Asset Cost",
    insurance_type: "Insurance Type",
    lot_cat: "LOT Cat",
    legal_case_id: "Legal Case ID",
    conciliator_name: "Conciliator Name",
    conciliation_date: "Conciliation Date",
    collection_manager_name: "Collection Manager Name",
    collection_manager_mobile_number: "Collection Manager Mobile Number",
    email: "Email",
    client_code: "Client Code",
    payment_link: "Payment Link",
    expected_maturity_date: "Expected Maturity Date",
    interest_outstanding: "Interest Outstanding",
    principal_outstanding: "Principal Outstanding",
    dob: "DOB",
    permanent_address: "Permanent Address",
    workplace_name: "Workplace Name",
    automatic_payment: "E-mandate Registered",
    additional_permanent_addresses: "Additional Permanent Addresses",
    additional_residence_addresses: "Additional Residence Addresses",
    additional_mobile_phone_number: "Additional Mobile Phone Number",
    additional_office_phone_number: "Additional Office Phone Number",
    opening_dpd: "Opening DPD",
    penalilty_overdue: "Penality Overdue",
    current_bucket: "Current Bucket",
    bom_bucket: "BOM Bucket",
    rble: "RBLE",
    vertical: "Vertical",
    hierarchy: "Hierarchy",
    co_applicant_name: "Co Applicant Name",
    co_applicant_address: "Co Applicant Address",
    co_applicant_pin_code: "Co Applicant Pincode",
    co_applicant_mobile_number: "Co Applicant Mobile Number",
    co_applicant_email: "Co Applicant Email",
    co_applicant_name2: "Co-Applicant Name 2",
    co_applicant_mobile_number2: "Co-Applicant Mobile Number 2",
    co_applicant_name3: "Co-Applicant Name 3",
    co_applicant_mobile_number3: "Co-Applicant Mobile Number 3",
    los_customer_barcode: "LOS Customer Barcode",
    los_gurantror_barcode: "LOS Gurantror Barcode",
    agreement_value: "Agreement Value",
    pre_sale_notice_date: "Pre Sale Notice Date",
    sale_date: "Sale Date",
    los_notice_date: "LOS Notice Date",
    total_outstanding_as_on: "Total Outstanding As On",
    sale_amount: "Sale Amount",
    lender_name: "Lender Name",
    min_collectible_amount: "Min Collectable Amount",
    risk_bucket: "Risk Bucket",
    minimum_due: "Minimum Due",
    card_no: "Card Number",
    pre_approved_sattlement_amount: "Pre Approved Settlement Amount",
    rcm_name: "RCM Name",
    rcm_contact_no:"RCM Number",
    zcm_name: "ZCM Name",
    zcm_number: "ZCM Number"
};

export function convertToMapping(columnObj) {
    if (!columnObj) return {};

    const newObj = {};
    for (const key in columnObj) {
        newObj[key] = {
            slug: convertToMapValue(columnObj?.[key]),
            column: columnObj?.[key],
        };
    }
    return { ...newObj };
}

export function convertToKeyColumn(mapping) {
    if (!mapping) return {};

    const newObj = {};
    for (const key in mapping) {
        newObj[key] = mapping?.[key]?.column;
    }

    return { ...newObj };
}
