import { apiGET, apiPOST } from "../utils/apiHandler";

export const getProductList = (params) => apiGET("/dashboard/api/product_name/", params);

export const issueFir = (body) => apiPOST("/fir/api/file_single_fir_from_notice/", body);

export const updateChequeDetails = (body) => apiPOST("/", body);

export const getNoticeTypes = (params) => apiGET("/pre_litigation/api/get_client_notice_types/", params);

export const postPoliceInquiryInitiated = (body) => apiPOST("/fir/api/police-enquiry-initiated/", body);

export const legalNoticeBatchUpload = (body) => apiPOST("/pre_litigation/api/client-batch-upload/", body);

export const generateLegalNotice = (params) => apiGET("/pre_litigation/api/bulk-pdf-sqs/", params);

export const getGeneratedLegalNoticeTemplates = (params) => apiGET("/pre_litigation/api/generated_legal_notice_templates/", params);

export const moveToNoticeBatch = (body) => apiPOST("/pre_litigation/api/move_to_notice_batch/", body);

export const getFirAnalysisReport = (params) => apiGET("/fir/api/fir_analysis_report_download/", params);

export const stateBreakup = (params) => apiGET("/fir/api/view_state_breakup/", params);

export const generateSampleNotice = (params) => apiGET("/pre_litigation/api/generate_sample_notice/", params);

export const getNoticeUpfrontMappingFields = (params) => apiGET("/pre_litigation/api/notice_upfront_fields_mapping/", params);

export const approveSampleNotice = (params) => apiGET("/pre_litigation/api/approve_sample_notice_and_generate_all/", params);

export const getAxisDeliveryReport = (params) => apiGET("/dashboard/api/axis_delivery_report/", params);

export const getLeadReport = (params) => apiGET("/dashboard/api/lead_report/", params);

export const getCollectionDailyReport = (params) => apiGET("/dashboard/api/daily_campaign_report/", params);

export const generateAuditReport = (params) => apiGET("/dashboard/api/generate_audit_report/", params);

export const createNoticeMasterCampaign = (params) => apiGET("/campaigns/api/notice_create_master_campaign/", params);

export const deleteNoticeBatch = (params) => apiGET("/pre_litigation/api/delete_notice_batch/", params);

export const getFirStates = (params) => apiGET("/fir/api/get_states/", params);

export const updateModelData = (body) => apiPOST("/dashboard/api/update_model_data/", body);

export const uploadWithdrawData = (body) => apiPOST("/dashboard/api/withdraw_data/", body);

export const getDailyReportFiles = (params) => apiGET("/dashboard/api/daily_report_files/", params);

export const failedPaymentDataUpload = (body) => apiPOST("/dashboard/api/failed_payment_data_upload/", body);

export const uploadPaymentList = (body) => apiPOST("/dashboard/api/update_customer_payment_data/", body);

export const getMailHistory = (params = { customer_mobile_number: null }) => apiGET("/freshdesk/api/email_webhook/", params);

export const getEmailReply = (params = { mail: null }) => apiGET("/freshdesk/api/get_email_reply/", params);

// file auto mapping
export const getFileMappings = (params = { client: null, type: null }) => apiGET("/dashboard/api/client_batch_mapping/", params);
export const createUpdateFileMappings = (body = { client: null, type: null, mapping: {} }) => apiPOST("/dashboard/api/client_batch_mapping/", body);

export const getDailyNoticeRespReportCron = (params = { batch_id: null, client_id: null }) => apiGET("/dashboard/api/daily_notice_resp_report_cron/", params);

export const getCustomerSettelmentManagementReport = (params) => apiGET("/dashboard/api/get_customer_settlement_management_report/", params);
export const postWhatsappDashboardata = (body) => apiPOST("/dashboard/api/send_broadcast_whatsapp/", body);
