import React, { useRef, useState } from "react";
import loginImage from "../../Static/RawImages/login.png";
import mediusLogo from "../../Static/RawImages/medius-logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import useAuthStore from "../../store/authStore";
import LandingPageRedirect from "../Components/LandingPageRedirect";
import { login, sendAgentOTP } from "../../API/commonApis";
import { Button, Modal } from "react-bootstrap";
import { IoCloseCircle } from "react-icons/io5";

export default function Login() {
  const createUser = useAuthStore((state) => state.createUser);
  const addFeatures = useAuthStore((state) => state.addFeatures);
  const removeUser = useAuthStore((state) => state.removeUser);
  const isUserAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const email = useRef(null);
  const password = useRef(null);
  const otp = useRef(null);

  const [authError, setAuthError] = useState("");
  const [otpInfo, setOTPInfo] = useState({
    type: null,
    message: null,
  });
  const [loading, setLoading] = useState(false);
  const [otpLoading, setOTPLoading] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false);

  const sendOTP = async (body = {}) => {
    const res = await sendAgentOTP({ ...body, email: email.current.value });

    const data = res || {
      status: false,
      message: "Can't send OTP! Please Try Again",
    };

    setOTPInfo({
      type: data.status ? "info" : "danger",
      message: data.message,
    });

    return data;
  };

  const handleOTP = async (e) => {
    e.preventDefault();

    if (!email.current.value) {
      setAuthError("Please Enter Email");
      return;
    }

    if (!password.current.value) {
      setAuthError("Please Enter Password");
      return;
    }

    setLoading(true);

    const res = await sendOTP();

    if (!res.status) {
      setAuthError(res.message);
      setLoading(false);
      return;
    }

    setShowOTPModal(true);
  };

  const handelSubmit = async (e) => {
    e.preventDefault();

    if (!email.current.value) {
      setAuthError("Please Enter Email");
      return;
    }

    if (!password.current.value) {
      setAuthError("Please Enter Password");
      return;
    }

    if (!otp.current.value) {
      setOTPInfo({
        type: "danger",
        message: "Please Enter OTP",
      });
      return;
    }

    setOTPLoading(true);
    setLoading(true);

    try {
      localStorage.clear();
      const formData = new FormData();
      formData.append("email", email.current.value?.toLowerCase()?.trim());
      formData.append("password", password.current.value);
      formData.append("otp", otp.current.value);

      const res = await login(formData);

      setLoading(false);
      setOTPLoading(false);

      let data = res.data;
      let message = res.message;

      if (res === "error") {
        setAuthError("Invalid Credentials.");
      }

      if (!data) {
        if (message?.match(/otp/i)) {
          setOTPInfo({
            type: "danger",
            message,
          });
        } else {
          setShowOTPModal(false);
        }

        setAuthError(message ?? "");
        return;
      }

      if (!["client"].includes(data?.role)) {
        setAuthError("Not A Client's Email");
        return;
      }

      removeUser();
      setAuthError("");

      let userData = !!data?.is_superadmin
        ? {
            email: data?.email,
            is_superadmin: !!data?.is_superadmin,
            full_name: data?.full_name,
            user_id: data?.user_id,
            client_id: data?.active_client?.client_id,
            logo_url: data?.active_client?.logo_url,
            active_client: data?.active_client,
            settings: { ...data?.settings },
          }
        : {
            email: data?.email,
            full_name: data?.full_name,
            user_id: data?.user_id,
            client_id: data?.active_client?.client_id,
            logo_url: data?.active_client?.logo_url,
            active_client: data?.active_client,
            settings: { ...data?.settings },
          };

      if (data?.masking_allowed === false) userData["remove_masking"] = true;

      createUser({
        token: data?.token,
        role: "client",
        userData,
      });
      // Close the modal only on successful login
      setShowOTPModal(false);
    } catch (error) {
      setAuthError("Unknown Error Occurred");
      console.error(error);
      setLoading(false);
      setOTPLoading(false);
    }
  };

  if (isUserAuthenticated) return <LandingPageRedirect />;

  return (
    <div style={{ backgroundImage: `url(${loginImage})` }} className="login">
      <div className="container">
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick />
        <div className="row justify-content-end">
          <div className="col-sm-6 col-md-5 col-lg-4">
            <form onSubmit={handleOTP}>
              <div className="login-logo">
                <img src={mediusLogo} alt="loginlogo" />
              </div>

              <div className="mb-3 row">
                <label htmlFor="login_email" className="col-sm-12 col-form-label">
                  Email Id
                </label>
                <div className="col-sm-12">
                  <input ref={email} type="text" autoComplete="off" name="" id="login_email" className="form-control" />
                </div>
              </div>
              <div className="mb-1 row">
                <label htmlFor="login_password" className="col-sm-12 col-form-label">
                  Password
                </label>
                <div className="col-sm-12">
                  <input ref={password} type="password" autoComplete="off" name="" id="login_password" className="form-control" />
                </div>
                <div style={{ marginTop: "1rem", fontSize: "0.9rem", color: "red" }} className="mb-3">
                  {authError}
                </div>
              </div>
              <div className="mb-0 row">
                <div className="col-sm-12">
                  <button type="submit" className="btn btn-primary w-100 mt-0">
                    GET OTP
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal size="md" show={showOTPModal} contentClassName="overflow-hidden text-white">
        <Modal.Header className="d-flex justify-content-between bg-dark">
          <Modal.Title className="text-white">Verify OTP</Modal.Title>
          <button
            onClick={() => {
              setShowOTPModal(false);
              setLoading(false);
            }}
            className="border-0 bg-transparent"
          >
            <IoCloseCircle size={24} color="white" />
          </button>
        </Modal.Header>
        <Modal.Body className="bg-dark">
          {otpInfo.type === "info" ? <p className="pt-2 text-info m-0">{otpInfo.message}</p> : <p className="pt-2 text-danger m-0">{otpInfo.message}</p>}

          <form onSubmit={handelSubmit}>
            <div className="d-flex p-4 gap-4 align-items-center">
              <label htmlFor="login_otp" className="text-white fw-medium">
                Enter OTP:
              </label>
              <input
                ref={otp}
                type="text"
                id="login_otp"
                maxLength={6}
                minLength={6}
                className="form-control"
                inputMode="numeric"
                pattern="\d{6}"
                title="OTP should be 6 digit numeric key"
                required
                style={{ maxWidth: "15ch", letterSpacing: "1ch", fontSize: "1.1rem", fontWeight: "bold" }}
                autoComplete="off"
              />
            </div>
            <Modal.Footer className="d-flex justify-content-start">
              <Button variant="primary" type="submit" disabled={otpLoading}>
                Submit OTP and Login
              </Button>
              <Button
                variant="link"
                className="text-decoration-none text-info"
                onClick={(e) => {
                  e.target.disabled = true;
                  sendOTP({ type: "resend" });
                }}
              >
                Resend OTP
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
