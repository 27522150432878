import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select, { components } from "react-select";
import { postUpdateCallAdminDisp, postDispositionData } from "../../../API/agentApis";
import useCallCustomer from "../../../hooks/useCallCustomer";
import useForm from "../../../hooks/useForm";
import useAgentStore from "../../../store/agent/agentStore";
import useGeneralStore from "../../../store/generalStore";
import DatePicker from "../../../ui/forms/DatePicker";
import { handleNotifications } from "../../../utils/handleNotification";
import WantSettlement from "../../features/borrower-info/components/want-settlement";
import useBorrowerInfo from "../../features/borrower-info/store";
import { getAccount } from "../../../apis";
import ReactTooltip from "react-tooltip";
const findPtpMaxDate = () => {
  const today = new Date();
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const nexSeventhDay = new Date(new Date().setDate(today.getDate() + 7));

  return nexSeventhDay < lastDayOfMonth ? nexSeventhDay : lastDayOfMonth;
};

const ptpMaxDate = findPtpMaxDate();

export default function PendingDispAddDispForm({ data = null, show, setShow, setData = () => {}, id = null, reloadData = () => {} }) {
  const [accdata, setAccountData] = useState();
  const [loading, setLoading] = useState(false);
  const [showWantSettlement, setShowWantSettlement] = useState(false);

  const additionalOptions = [
    { value: "VEHICLE_LOST", label: "VEHICLE LOST (VEHICLE_LOST)" },
    { value: "VEHICLE_ALREADY_SURRENDERED", label: "VEHICLE ALREADY SURRENDERED" },
    { value: "READY_TO_SURRENDER_VEHICLE", label: "READY TO SURRENDER VEHICLE" },
  ];

  const dispOptions = [
    {
      label: "Primary Options",
      options: [
        { value: "PTP", label: "Promise To Pay (PTP)" },
        { value: "PAID", label: "Fully PAID" },
        { value: "P-PAID", label: "Partially PAID" },
        { value: "RTP", label: "Refuse To Pay (RTP)" },
        { value: "call_dropped", label: "Call Dropped" },
        { value: "blank_call", label: "Blank Call" },
        { value: "will_pay_later", label: "Will Pay Later" },
        { value: "WN", label: "Wrong Number (WN)" },
        { value: "SETL", label: "Want settlement" },
        ...(accdata?.data?.data?.product_name &&
        (accdata?.data?.data?.product_name.toLowerCase() === "tw" ||
          accdata?.data?.data?.product_name.toLowerCase() === "two wheeler" ||
          ["car", "bike", "vehicle", "used"].some((keyword) => accdata?.data?.data?.product_name.toLowerCase().includes(keyword)))
          ? additionalOptions
          : []),
      ],
    },
    {
      label: "Secondary Options",
      options: [
        // { value: "SW", label: "Switch Off (SW)" },
        // { value: "RNR", label: "Ringing No Response (RNR)" },
      ],
    },
    {
      label: "Other Options",
      options: [
        { value: "DND", label: "Do Not Disturb (DND)" },
        { value: "BPTP", label: "Broken PTP (BPTP)" },
        { value: "DIS", label: "Dispute (DIS)" },
        { value: "LANG", label: "Language Barrier (LANG)" },
        { value: "DECEASED", label: "Deceased (DECEASED)" },
        { value: "SUR", label: "Surrender (SUR)" },
      ],
    },
  ];

  const flattenedDispOptions = dispOptions.map(({ options }) => options).flat();

  const { callCustomer, callTypes } = useCallCustomer();
  const reload = useAgentStore((state) => state.reload);
  const showAlert = useGeneralStore((state) => state.open_alert);

  const [dispForm, handleDispForm, resetDispForm] = useForm({
    disposition: "",
    ptp_amount: "",
    paid_amount: "",
    ptp_date: null,
    remark: "",
    alt_contact_number: "",
    surrendered_date: "",
    surrendered_to: "",
    valid_insurance: "",
    police_complain_filed: "",
  });

  const closeModal = () => {
    setShow(false);
    resetDispForm();
    setData(null);
  };

  const addDisposition = async () => {
    setLoading(true);
    try {
      const [res, callRecords] = await Promise.all([
        postDispositionData({
          customer: data?.customer?.customer_id,
          loan_account_no: data?.customer?.loan_account_no ? data?.customer?.loan_account_no : null,
          batch_id: data?.batch_id ? data?.batch_id : null,
          product_name: data?.customer?.product_name ? data?.customer?.product_name : null,
          due_agmt_no: data?.customer?.loan_account_no ? data?.customer?.loan_account_no : null,
          customer_name: data?.customer?.customer_name ? data?.customer?.customer_name : null,
          mobile_number: data?.To ? data?.To : null,
          contact_date: !!data?.DateUpdated ? format(new Date(data?.DateUpdated), "yyyy-MM-dd") : null,
          due_amount: data?.customer?.loan_amount ? data?.customer?.loan_amount : 0,
          payment_mode: null,
          next_action: null,
          disposition: dispForm?.disposition,
          alt_contact_number: dispForm.alt_contact_number ? dispForm.alt_contact_number : null,
          remark: dispForm.remark ? dispForm.remark : null,
          ptp_amount: dispForm.ptp_amount ? Number(dispForm.ptp_amount) : 0,
          ptp_date: dispForm.ptp_date ? format(new Date(dispForm.ptp_date), "yyyy-MM-dd") : null,
          paid_amount: dispForm.paid_amount ? Number(dispForm.paid_amount) : 0,
          settlement_amount: 0,
          number_of_emi: 0,
          settlement_emi_data: null,
          agency_name: "The Medius",
          channel: "HUMAN_CALL",
          idle_time: 0,
          chance_of_payment: 0,
          surrendered_date: dispForm.surrendered_date ? format(new Date(dispForm.surrendered_date), "yyyy-MM-dd") : null,
          surrendered_to: dispForm.surrendered_to ? dispForm.surrendered_to : null,
          valid_insurance: dispForm.valid_insurance ? dispForm.valid_insurance : null,
          police_complain_filed: dispForm.police_complain_filed ? dispForm.police_complain_filed : null,
          blank_call_reason: dispForm.blank_call_reason ? dispForm.blank_call_reason : null,
          call_dropped_reason: dispForm.call_dropped_reason ? dispForm.call_dropped_reason : null,
          will_pay_later_date: dispForm.pay_later_date ? format(new Date(dispForm.pay_later_date), "yyyy-MM-dd") : null,
        }),
        postUpdateCallAdminDisp({ id, disposition: dispForm.disposition }),
      ]);

      if (res) {
        let message = handleNotifications({
          customer_mobile_number: data?.To,
          message: res.success_message,
          notification_type: res.notification_type,
          callCustomer: () => callCustomer({ call_type: callTypes.AGENT, customer_mobile_number: data?.To }),
        });
        reloadData();
        closeModal();
        showAlert({ variant: "success", msg: message });
      } else {
        showAlert({ variant: "danger", msg: "Failed to Add Disposition" });
      }

      if (!callRecords) {
        showAlert({ variant: "danger", msg: "Failed to Add Call Records" });
      }
    } catch (error) {
      console.error("Error in addDisposition:", error);
      showAlert({ variant: "danger", msg: "An error occurred while processing your request." });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchAccountDetails = async () => {
      try {
        const res = await getAccount({ customer_id: data?.customer?.customer_id });
        setAccountData(res);
      } catch (error) {
        console.error("Error fetching account details:", error);
      }
    };
    if (data?.customer?.customer_id) fetchAccountDetails();
  }, [data?.customer?.customer_id]);
  return (
    <>
      <Modal className="popup-style-one disp-popup" size="md" show={show}>
        <Modal.Header>
          <Modal.Title>Add Disposition</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="popup-content min-height-content">
            <div className="item-cl bg-white px-2 rounded-8 h-100">
              <div className="item-cl-head">
                <div className="row-field mb-3">
                  <div className="filter-label space-mb-8">Disposition</div>
                  <Select
                    placeholder="Select"
                    isClearable={true}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        padding: "0.2rem 0",
                        paddingLeft: "0.4rem",
                        border: "1px solid #dddddd !important",
                        borderRadius: "6px",
                        backgroundColor: state.isDisabled ? "#f1f1f1" : "#fff",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? "#E0E7E9" : state.isFocused ? "#f4f6f8" : "white",
                        color: !state.isSelected && state.isDisabled ? "#aaa" : "#555",
                        zIndex: "999",
                      }),
                    }}
                    options={dispOptions}
                    value={flattenedDispOptions?.find((el) => el?.value === dispForm?.disposition) || ""}
                    onChange={(val) => handleDispForm("disposition", !!val?.value ? val?.value : "")}
                    components={{
                      Group: (props) => (
                        <div style={{ borderBottom: `1px solid #e0e0e0` }}>
                          <components.Group {...props} />
                        </div>
                      ),
                    }}
                  />
                </div>

                {["PTP"].includes(dispForm?.disposition) && (
                  <div className="row-field space-mt-16">
                    <div className="filter-label space-mb-8">Amount</div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Amount"
                      value={dispForm?.ptp_amount}
                      onChange={(e) => (!isNaN(e.target.value) ? handleDispForm("ptp_amount", e.target.value) : {})}
                    />
                  </div>
                )}

                {["PAID", "P-PAID"].includes(dispForm?.disposition) && (
                  <div className="row-field space-mt-16">
                    <div className="filter-label space-mb-8">Amount</div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Amount"
                      value={dispForm?.paid_amount}
                      onChange={(e) => (!isNaN(e.target.value) ? handleDispForm("paid_amount", e.target.value) : {})}
                    />
                  </div>
                )}

                {["WN"].includes(dispForm?.disposition) && (
                  <div className="row-field space-mt-16">
                    <div className="filter-label space-mb-8">Alternate Number</div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Phone Number"
                      value={dispForm?.alt_contact_number}
                      minLength={10}
                      maxLength={10}
                      onChange={(e) => (!isNaN(e.target.value) ? handleDispForm("alt_contact_number", e.target.value) : {})}
                    />
                  </div>
                )}

                {["RTP"].includes(dispForm?.disposition) && (
                  <div className="row-field space-mt-16">
                    <div className="filter-label space-mb-8">Reason</div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Reason"
                      value={dispForm?.remark}
                      onChange={(e) => handleDispForm("remark", e.target.value)}
                    />
                  </div>
                )}

                {["PTP"].includes(dispForm?.disposition) && (
                  <div className="row-field space-mt-16">
                    <div className="filter-label space-mb-8">Date</div>
                    <DatePicker label="Select Date" value={dispForm?.ptp_date} maxDate={ptpMaxDate} minDate={new Date()} onChange={(val) => handleDispForm("ptp_date", val)} />
                  </div>
                )}

                {dispForm?.disposition === "VEHICLE_ALREADY_SURRENDERED" && (
                  <>
                    <div className="mb-3">
                      <label className="form-label mb-1">Date of Surrender</label>
                      <DatePicker label="Select Date" value={dispForm?.surrendered_date} onChange={(val) => handleDispForm("surrendered_date", val)} />
                    </div>

                    <div className="mb-3">
                      <label className="form-label mb-1">Surrendered To</label>
                      <div style={{ display: "flex", gap: "24px" }}>
                        <div style={{ display: "flex", alignContent: "center", gap: "5px" }}>
                          <input
                            type="radio"
                            id="showroom"
                            name="surrendered_to"
                            value="Showroom"
                            onChange={(e) => handleDispForm("surrendered_to", e.target.value)}
                            className="mr-4"
                          />
                          <label htmlFor="showroom" className="mr-5">
                            Showroom
                          </label>
                        </div>
                        <div style={{ display: "flex", alignContent: "center", gap: "5px" }}>
                          <input
                            type="radio"
                            id="agency"
                            name="surrendered_to"
                            value="Agency"
                            onChange={(e) => handleDispForm("surrendered_to", e.target.value)}
                            className="mr-4"
                          />
                          <label htmlFor="agency">Agency</label>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {dispForm?.disposition === "VEHICLE_LOST" && (
                  <>
                    <div className="mb-3">
                      <label className="form-label mb-1">Valid Insurance</label>
                      <div style={{ display: "flex", gap: "24px" }}>
                        <div style={{ display: "flex", alignContent: "center", gap: "5px" }}>
                          <input
                            type="radio"
                            id="insurance_yes"
                            name="valid_insurance"
                            value="Yes"
                            onChange={(e) => handleDispForm("valid_insurance", e.target.value)}
                          />
                          <label htmlFor="insurance_yes" className="mr-3">
                            Yes
                          </label>
                        </div>
                        <div style={{ display: "flex", alignContent: "center", gap: "5px" }}>
                          <input
                            type="radio"
                            id="insurance_no"
                            name="valid_insurance"
                            value="No"
                            onChange={(e) => handleDispForm("valid_insurance", e.target.value)}
                          />
                          <label htmlFor="insurance_no">No</label>
                        </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <label className="form-label mb-1">Police Complaint Filed</label>
                      <div style={{ display: "flex", gap: "24px" }}>
                        <div style={{ display: "flex", alignContent: "center", gap: "5px" }}>
                          <input
                            type="radio"
                            id="complaint_yes"
                            name="police_complaint"
                            value="Yes"
                            onChange={(e) => handleDispForm("police_complain_filed", e.target.value)}
                          />
                          <label htmlFor="complaint_yes" className="mr-3">
                            Yes
                          </label>
                        </div>

                        <div style={{ display: "flex", alignContent: "center", gap: "5px" }}>
                          <input
                            type="radio"
                            id="complaint_no"
                            name="police_complaint"
                            value="No"
                            onChange={(e) => handleDispForm("police_complain_filed", e.target.value)}
                          />
                          <label htmlFor="complaint_no">No</label>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {["SETL"].includes(dispForm?.disposition) && (
                  <button onClick={() => setShowWantSettlement(true)} className="mt-2 btn btn-primary btn-lg w-100 rounded">
                    Add Disposition
                  </button>
                )}

                {dispForm?.disposition === "call_dropped" && (
                  <>
                    <div className="mb-3 mt-3">
                      <label className="form-label mb-1">Reason for Call Dropped</label>
                      <div style={{ display: "flex", gap: "16px", marginTop: "12px" }}>
                        <div style={{ display: "flex", alignContent: "center", gap: "5px" }}>
                          <input
                            type="radio"
                            id="borrower_disconnected"
                            name="call_dropped_reason"
                            value="Borrower intentionally disconnected"
                            onChange={(e) => handleDispForm("call_dropped_reason", e.target.value)}
                          />
                          <label htmlFor="borrower_disconnected">Borrower intentionally disconnected</label>
                        </div>
                        <div style={{ display: "flex", alignContent: "center", gap: "5px" }}>
                          <input
                            type="radio"
                            id="network_issue"
                            name="call_dropped_reason"
                            value="Network Issue"
                            onChange={(e) => handleDispForm("call_dropped_reason", e.target.value)}
                          />
                          <label htmlFor="network_issue">Network Issue</label>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {dispForm?.disposition === "blank_call" && (
                  <>
                    <div className="mb-3">
                      <label className="form-label mb-1">Reason for Blank Call</label>
                      <div style={{ display: "flex", gap: "24px", marginTop: "12px" }}>
                        <div style={{ display: "flex", alignContent: "center", gap: "5px" }}>
                          <input
                            type="radio"
                            id="borrower_not_speaking"
                            name="blank_call_reason"
                            value="No Voice Detected"
                            onChange={(e) => handleDispForm("blank_call_reason", e.target.value)}
                          />
                          <label htmlFor="borrower_not_speaking">No Voice Detected</label>
                          <span data-tip data-for="NoVoiceDetected" style={{ cursor: "pointer", marginLeft: "5px" }}>
                            ℹ️
                          </span>
                          <ReactTooltip effect="solid" place="right" className="py-2 px-3" id="NoVoiceDetected">
                            <div style={{ fontWeight: "normal" }}>
                              <strong>No Voice Detected</strong>
                              <ul>
                                <li>Complete silence on call from borrower due to mute or network issues</li>
                              </ul>
                            </div>
                          </ReactTooltip>
                        </div>
                        <div style={{ display: "flex", alignContent: "center", gap: "5px" }}>
                          <input
                            type="radio"
                            id="blank_call_network_issue"
                            name="blank_call_reason"
                            value="Background Noise"
                            onChange={(e) => handleDispForm("blank_call_reason", e.target.value)}
                          />
                          <label htmlFor="blank_call_network_issue">Background Noise</label>
                          <span data-tip data-for="BackgroundNoise" style={{ cursor: "pointer", marginLeft: "5px" }}>
                            ℹ️
                          </span>
                          <ReactTooltip effect="solid" place="right" className="py-2 px-3" id="BackgroundNoise">
                            <div style={{ fontWeight: "normal" }}>
                              <strong>Background Noise</strong>
                              <ul>
                                <li>Background noise on call & borrower intentionally not speaking</li>
                              </ul>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {dispForm?.disposition === "will_pay_later" && (
                  <>
                    <div className="mb-3">
                      <label className="form-label mb-1">Select Payment Date</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Enter Payment Date"
                        min={`${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, "0")}-08`}
                        max={`${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, "0")}-25`}
                        value={dispForm?.pay_later_date}
                        onChange={(e) => handleDispForm("pay_later_date", e.target.value)}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeModal()}>
            Close
          </Button>
          <Button disabled={!dispForm?.disposition || loading} onClick={() => addDisposition()} variant="primary">
            {loading ? "Saving..." : "Save"}
          </Button>
        </Modal.Footer>
        <WantSettlement
          show={showWantSettlement}
          customer_id={data?.customer?.customer_id}
          setShow={setShowWantSettlement}
          disposition={dispForm?.disposition}
          reset={resetDispForm}
        />
      </Modal>
    </>
  );
}
