import React from "react";
import { useLocation } from "react-router-dom";
import useAuthStore from "../../store/authStore";
import LandingPageRedirect from "./LandingPageRedirect";

export const panelFeatures = {
    DASHBOARD: {
        key: "dashboard",
        route: "/",
    },
    COLLECTION: {
        key: "collection",
        route: "/collection",
    },
    COLLECTION_BATCH: {
        key: "collection_batch",
        route: "/collection/batch",
    },
    SETTLEMENT_TRACKER: {
        key: "settlement_tracker",
        route: "/collection/settlement-tracker",
    },
    DAILY_SETL_REPORT: {
        key: "daily_settlement_report",
        route: "/collection/daily-settlement-report",
    },
    PRELITIGATION: {
        key: "prelitigation",
        route: "/prelitigation",
    },
    PRELITIGATION_NOTICES: {
        key: "prelitigation_notices",
        route: "/prelitigation/legal",
    },
    PRELITIGATION_NOTICES_BATCH: {
        key: "prelitigation_notices_batch",
        route: "/prelitigation/legal/batch",
    },
    PRELITIGATION_NOTICES_NOTICES: {
        key: "prelitigation_notices_notices",
        route: "/prelitigation/legal/notices",
    },
    PRELITIGATION_FIR: {
        key: "prelitigation_fir",
        route: "/prelitigation/fir",
    },
    PRELITIGATION_FIR_BATCH: {
        key: "prelitigation_fir_batch",
        route: "/prelitigation/fir/batch",
    },
    PRELITIGATION_FIR_COMPLAINTS: {
        key: "prelitigation_fir_complaints",
        route: "/prelitigation/fir/complaints",
    },
    ALL_FILES: {
        key: "all_files",
        route: "/all-files",
    },
    ALL_FILES_VIEW_FILES: {
        key: "all_files_view_files",
        route: "/all-files/reports",
    },
    ALL_FILES_DOWNLOAD_FILES: {
        key: "all_files_download_files",
        route: "/all-files/all-downloads",
    },
    WHATSAPP: {
        key: "whatsapp",
        route: "/whatsapp/dashboard",
    },
    WHATSAPP_DASHBOARD: {
        key: "whatsapp",
        route: "/whatsapp/whatsapp_dashboard",
    },
};

export const panelFeaturesArr = Object.values(panelFeatures);

const isRouteDisabled = (route, allowed = []) => {
    // DISABLED FOR EVERYONE
    // if (route?.indexOf("/collection/dailyreport") > -1) return true;
    // if (route?.indexOf("/collection/batch") > -1) return true;

    const currentRouteDetails = panelFeaturesArr.find((el) => el?.route === route);
    if (!!currentRouteDetails) return !allowed.includes(currentRouteDetails?.key);

    // if (!!userSpecific?.[panelSettingConstants.HIDE_DASHBOARD]) {
    //     if (route === "/") return true;
    // }

    // if (!!userSpecific?.[panelSettingConstants.HIDE_COLLECTION]) {
    //     if (route?.indexOf("/collection") > -1) return true;
    // }

    // if (!!userSpecific?.[panelSettingConstants.HIDE_PRELITIGATION]) {
    //     if (route?.indexOf("/prelitigation") > -1) return true;
    // }

    // if (!!userSpecific?.[panelSettingConstants.HIDE_FIR]) {
    //     if (route?.indexOf("/prelitigation/fir") > -1) return true;
    // }

    return false;
};

export default function ControlledNavigation({ component: Component }) {
    const features = useAuthStore((state) => state.features);
    const location = useLocation();

    if (isRouteDisabled(location.pathname, features || [])) return <LandingPageRedirect />;
    else return <Component />;
}
