import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { IoMdCloseCircle, IoIosCall } from "react-icons/io";
import { AiOutlineUser } from "react-icons/ai";
import { postManualCallInitiate } from "../../../API/agentApis";
import useAgentStore from "../../../store/agent/agentStore";
import Bell from "../../../Static/RawImages/bell.png";
import { Button } from "react-bootstrap";
import { maskMobileNumber } from "../../../Functions/mask";
import useAuthStore from "../../../store/authStore";

const icons = {
  user: {
    color: "rgba(80, 80, 80, 0.6)",
    icon: <AiOutlineUser />,
  },
  call: {
    color: "rgba(255, 59, 48, 0.6)",
    icon: <IoIosCall />,
  },
};

export default function NotificationModal() {
  const notificationData = useAgentStore((state) => state.common_activity_data);

  console.log("Notification Data: ", notificationData);

  const [show, setShow] = useState(false);

  const closeModal = () => {
    setShow(false);
  };

  return (
    <>
      <Button variant="link" className="ps-0 pe-0" onClick={() => setShow(true)}>
        <img src={Bell} alt="Notification" />
      </Button>
      {!!notificationData?.length && (
        <span
          style={{
            position: "relative",
            top: "-5px",
            left: "-15px",
            background: "#F44336",
            color: "#FFF",
            borderRadius: "50%",
            fontSize: "8px",
            padding: "1px 4px",
          }}
        >
          {notificationData?.length || 0}
        </span>
      )}

      <Modal show={show} onHide={() => closeModal()}>
        <Modal.Header>
          <Modal.Title>Notifications {!!notificationData?.length && `(${notificationData.length})`}</Modal.Title>
          <button
            type="button"
            style={{ opacity: "0.4", color: "#000", fontSize: "24px", lineHeight: "0" }}
            onClick={() => closeModal()}
            className="btn p-0"
            aria-label="Close"
          >
            <IoMdCloseCircle />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div style={{ maxHeight: "450px", overflowY: "scroll", overflowX: "hidden", padding: "10px" }}>
            {!!notificationData?.length &&
              notificationData?.map((el, index) => (
                <NotificationItem
                  key={index}
                  name={el?.customer_name || "No Name Found"}
                  city=""
                  state=""
                  mobile={el?.to_number || "No Number Found"}
                  icon={icons.user}
                />
              ))}
            {!notificationData?.length && <p className="text-center py-3">No Notifications Found</p>}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

function NotificationItem({ name = "", city = "", state = "", mobile = "", icon }) {
  const userData = useAuthStore((state) => state.userData);

  const initiateCall = async () => {
    await postManualCallInitiate({ to_number: mobile, call_type: "agent" });
    navigator.clipboard.writeText(mobile);
  };

  return (
    <div className="row b-b-1-dash pt-2 pb-2">
      <div className="col-md-1 pe-0 ps-0 text-center d-flex align-items-center justify-content-center">
        <span
          style={{
            border: `1px solid ${icon.color}`,
            color: `${icon.color}`,
            borderRadius: "50%",
            height: "30px",
            width: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "18px",
          }}
        >
          {icon.icon}
        </span>
      </div>
      <div className="col-md-9">
        <p className="mb-0 text-black">
          {[name, city, state].filter((el) => !!el).join(", ")}
          <br />
          {!!userData?.remove_masking ? mobile : maskMobileNumber(mobile)}
        </p>
      </div>
      <div className="col-md-2 text-end">
        <button onClick={initiateCall} style={{ outline: "none", border: "none", background: "none" }}>
          <svg width="31" height="31" viewBox="0 0 31 31" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
              <circle fill="#009EC0" cx="15.4" cy="15.4" r="15.4" />
              <path
                d="M21.46 18.871a.638.638 0 0 0-.54-.659 8.783 8.783 0 0 1-1.923-.479.633.633 0 0 0-.667.14l-.806.806a.634.634 0 0 1-.762.103 10.784 10.784 0 0 1-4.044-4.044.634.634 0 0 1 .103-.762l.803-.804a.635.635 0 0 0 .142-.67 8.765 8.765 0 0 1-.477-1.916.636.636 0 0 0-.641-.545h-1.903a.635.635 0 0 0-.632.681c.198 1.862.831 3.651 1.852 5.227a11.743 11.743 0 0 0 3.616 3.614 11.91 11.91 0 0 0 5.187 1.848.634.634 0 0 0 .691-.637v-1.903zm1.268.007v1.894a1.903 1.903 0 0 1-2.086 1.902 13.182 13.182 0 0 1-5.746-2.043 12.998 12.998 0 0 1-3.999-3.997 13.198 13.198 0 0 1-2.046-5.788 1.903 1.903 0 0 1 1.894-2.074h1.897c.955-.01 1.77.69 1.904 1.642.074.562.211 1.113.409 1.643.261.695.094 1.48-.431 2.01l-.455.455a9.516 9.516 0 0 0 2.909 2.91l.457-.458a1.903 1.903 0 0 1 2.007-.43c.53.199 1.082.336 1.65.411.953.135 1.656.96 1.636 1.923z"
                fill="#FFF"
              />
            </g>
          </svg>
        </button>
      </div>
    </div>
  );
}
